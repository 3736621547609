import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { Typography } from "@mui/material";

export const renderizarGrafico = (itemSelecionado, nota, semana, item) => {
  if (nota?.length === 0 || semana?.length === 0) {
    return (
      <Typography
        variant="h6"
        sx={{
          mt: 3,
          color: "#212B2F",
          fontWeight: "600",
          fontSize: "1.125rem",
          lineHeight: "1.5",
        }}
      >
        Nenhum dado disponível
      </Typography>
    );
  }

  if (
    itemSelecionado === "Alimentação" ||
    itemSelecionado === "Ingestão água" ||
    itemSelecionado === "Tabagismo" ||
    itemSelecionado === "Ingestão bebida alcoólica"
  ) {
    return (
      <LineChart
        height={400}
        series={[
          {
            data: nota,
            label: "Resposta",
            area: true,
            showMark: false,
          },
        ]}
        xAxis={[{ scaleType: "point", data: semana }]}
        sx={{
          ".MuiLineElement-root": {
            display: "none",
          },
        }}
      />
    );
  } else if (
    itemSelecionado === "Sono qualitativo" ||
    itemSelecionado === "Sono quantitativo" ||
    itemSelecionado === "Autoestima" ||
    itemSelecionado === "Disposição" ||
    itemSelecionado === "Intensidade treino" ||
    itemSelecionado === "Organização" ||
    itemSelecionado === "Frequencia motivação"
  ) {
    return (
      <LineChart
        xAxis={[{ scaleType: "point", data: semana }]}
        series={[
          {
            data: nota,
          },
        ]}
        height={400}
      />
    );
  } else if (itemSelecionado === "Dores") {
    if (item) {
      // Cria um mapa para agrupar as notas por semana
      const dataMap = new Map();

      semana.forEach((data, index) => {
        if (!dataMap.has(data)) {
          dataMap.set(data, {});
        }
        /*
        const weekData = dataMap.get(data);
        weekData[item[index]] = nota[index];
        dataMap.set(data, weekData);
        */
       
        const weekData = dataMap.get(data);
        // Inverte a escala dos valores de nota
        const notaInvertida = 5 - nota[index];
        weekData[item[index]] = notaInvertida;
        dataMap.set(data, weekData);
      });

      // Converte o mapa de dados para uma lista de objetos
      const data = Array.from(dataMap).map(([semana, notas]) => ({
        semana,
        ...notas,
      }));

      return (
        <div>
          <BarChart
            dataset={data}
            xAxis={[{ scaleType: "band", dataKey: "semana" }]}
            series={item.map((nome) => ({
              dataKey: nome,
              label: nome,
            }))}
            height={400}
          />
        </div>
      );
    }
  } else if (itemSelecionado === "Doenças") {
    if (item) {
      // Cria um objeto para mapear os dados por semana
      const dataMap = {};

      // Popula o objeto de dados com as informações
      semana.forEach((data, index) => {
        if (!dataMap[data]) {
          dataMap[data] = {};
        }
        dataMap[data][item[index]] = nota[index];
      });

      // Prepara os dados para o gráfico
      const data = Object.keys(dataMap).map((semana) => ({
        semana,
        ...dataMap[semana],
      }));

      // Prepara a série para o gráfico
      const series = Object.keys(dataMap[data[0].semana]).map((nome) => ({
        dataKey: nome,
        label: nome,
      }));

      return (
        <div>
          <BarChart
            dataset={data}
            xAxis={[{ scaleType: "band", dataKey: "semana" }]}
            series={series}
            height={400}
          />
        </div>
      );
    }
  }
};
