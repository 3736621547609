import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Word, Excel, Pdf } from "./icons";
import { api } from "../boot/axios";

export default function Funcoes() {
  const navigate = useNavigate();

  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const endSession = () => {
    api.get("/csrf-cookie").then(() => {
      api
        .post(
          "/logout-api",
          {},
          {
            headers,
          }
        )
        .then(() => {
          Cookies.remove("logged", { path: "/" });
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const Markdown = (data) => {
    return (
      <div>
        <ReactMarkdown children={data} />
      </div>
    );
  };

  const getIconByFileType = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();

    switch (fileExtension) {
      case "xls":
      case "xlsx":
        return <Excel />;
      case "doc":
      case "docx":
        return <Word />;
      case "pdf":
        return <Pdf />;
      default:
        return null;
    }
  };

  return {
    endSession,
    Markdown,
    getIconByFileType,
  };
}
