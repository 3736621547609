import { api } from "../boot/axios";

export default function UseApi(url) {
	const csrfCookie = async () => {
		await api.get("/csrf-cookie");
	};

	return {
		csrfCookie,
	};
}
