import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import firstAccessService from "./firstAccessService";
import {
  Typography,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Container,
} from "@mui/material";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function FirstAccessCode() {
  const { checkCode } = firstAccessService();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = { user_uuid: uuid, otp: data.get("otp") };

    try {
      const response = await checkCode(form);
      navigate(`/primeiro-acesso/${response.user_uuid}/${response.otp}`);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Typography component="p" variant="subtitle1" align="center">
            Digite o codigo enviado em seu email
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: "100%" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="otp"
                  label="Codigo de segurança"
                  name="otp"
                  autoComplete="otp"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Enviar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Voltar para o Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
