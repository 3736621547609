import React, { useEffect, useState } from "react";
import {
  Avatar,
  Stack,
  Skeleton,
  SvgIcon,
  Typography,
  CssBaseline,
  Box,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import funcService from "../../Funcs/funcService";
import alunoService from "../../Alunos/alunoService";
import feedbackService from "../../Feedback/feedbackService";
import educacaoService from "../../Educacao/educacaoService";
import calendarioService from "../../Calendario/calendarioService";

export default function DashboardFuncView({ uuid }) {
  const { getFunc } = funcService();
  const { getAlunos } = alunoService();
  const { getDoencas } = feedbackService();
  const { getVideos } = educacaoService();
  const { getCalendario } = calendarioService();
  const [isLoading, setIsLoading] = useState(true);
  const [totalFunc, setTotalFunc] = useState();
  const [totalAlunos, setTotalAlunos] = useState();
  const [totalDoencas, setTotalDoencas] = useState();
  const [totalVideos, setTotalVideos] = useState();
  const [totalEventos, setTotalEventos] = useState();

  useEffect(() => {
    async function getData() {
      try {
        const funcs = await getFunc();
        setTotalFunc(funcs.meta.total);
        const alunos = await getAlunos();
        setTotalAlunos(alunos.meta.total);
        const doencas = await getDoencas();
        setTotalDoencas(doencas.meta.total);
        const videos = await getVideos();
        setTotalVideos(videos.meta.total);
        const eventos = await getCalendario("true");
        setTotalEventos(eventos.length);
        setIsLoading(false); // Definindo isLoading como false após carregar os dados
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          mb: 3,
        }}
      >
        <Grid container spacing={3}>
          {isLoading // Verificando se os dados estão sendo carregados
            ? // Renderizando um esqueleto enquanto os dados estão sendo carregados
              [...Array(5)].map((_, index) => (
                <Grid item key={index} xs={12} sm={6} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      borderRadius: "16px",
                    }}
                  >
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography variant="h6">
                          <Skeleton variant="text" width={200} />
                        </Typography>
                        <Typography variant="h6">
                          <Skeleton variant="text" width={50} />
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          height: 56,
                          width: 56,
                        }}
                      >
                        <SvgIcon>
                          <PeopleIcon />
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            : // Renderizando os dados reais
              [
                {
                  label: "Funcionários",
                  total: totalFunc,
                  icon: <PeopleIcon />,
                },
                {
                  label: "Alunos",
                  total: totalAlunos,
                  icon: <PersonAddIcon />,
                },
                {
                  label: "Doenças cadastradas",
                  total: totalDoencas,
                  icon: <LocalHospitalIcon />,
                },
                {
                  label: "Videos publicados",
                  total: totalVideos,
                  icon: <PlayCircleIcon />,
                },
                {
                  label: "Eventos no calendário",
                  total: totalEventos,
                  icon: <CalendarMonthIcon />,
                },
              ].map((item, index) => (
                <Grid item key={index} xs={12} sm={6} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      borderRadius: "16px",
                    }}
                  >
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#212B2F",
                            fontWeight: "700",
                            fontSize: "1.125rem",
                            lineHeight: "1.5",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <Typography variant="h6">{item.total}</Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          height: 56,
                          width: 56,
                        }}
                      >
                        <SvgIcon>{item.icon}</SvgIcon>
                      </Avatar>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Box>
    </Container>
  );
}
