import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  CssBaseline,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useNavigate, useParams } from "react-router-dom";
import anamneseService from "../anamneseService";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function Alunos() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { getAnamnese, deleteAnamnese } = anamneseService();
  const [isLoading, setIsLoading] = useState(true);
  const [anamneses, setAnamneses] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAnamnese(uuid);
        setAnamneses(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const removeAnamnese = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover a anamnese?"
    );

    if (confirmRemoval) {
      try {
        await deleteAnamnese(uuid);
        toast.success("Anamnese removida com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const editAnamnese = async (uuidAnamnese) => {
    const confirm = window.confirm("Tem certeza que deseja editar a anamnese?");

    if (confirm) {
      try {
        navigate(`/alunos/anamnese/${uuid}/edit/${uuidAnamnese}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  const postAnamnese = async () => {
    const confirm = window.confirm(
      "Tem certeza que deseja cadastrar anamnese?"
    );

    if (confirm) {
      try {
        navigate(`/alunos/cadastro/anamnese/${uuid}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 2,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  postAnamnese();
                }}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      >
                        Índice
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {anamneses.map((anamnese, index) => (
                      <TableRow
                        key={anamnese.uuid}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {`${index + 1}ª Anamnese`}
                        </TableCell>
                        <TableCell align="right">
                          <ButtonGroup variant="contained" size="large">
                            <Button
                              onClick={() => {
                                editAnamnese(anamnese.uuid);
                              }}
                              title="Editar"
                              startIcon={<EditIcon />}
                            ></Button>
                            <Button
                              onClick={() => {
                                removeAnamnese(anamnese.uuid);
                              }}
                              title="Remover"
                              startIcon={<RemoveCircleIcon />}
                            ></Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Button
              onClick={() => {
                navigate("/alunos");
              }}
              sx={{ mt: 2 }}
            >
              Voltar
            </Button>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
