import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  Container,
  Box,
  CssBaseline,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import feedbackService from "../feedbackService";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";

export default function ShowFeedbackAluno() {
  const { getFeedbacks, showFeedback } = feedbackService();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [feedbacksList, setFeedbacksList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeeback] = useState();
  const [open, setOpen] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function getData() {
      try {
        const response = await getFeedbacks(uuid);
        setFeedbacksList(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);

  const viewFeedback = async (uuid) => {
    try {
      const response = await showFeedback(uuid);
      setFeeback(response);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = (date) => {
    if (date) {
      const selectedDate = dayjs(date);
      setFilterDate(selectedDate);
    } else {
      setFilterDate(null);
    }
  };

  const filteredFeedbacks = feedbacksList.filter((feedback) => {
    if (!filterDate) return true;
    return (
      dayjs(feedback.data).year() === filterDate.year() &&
      dayjs(feedback.data).month() === filterDate.month()
    );
  });

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(6),
      maxWidth: "630px",
      minHeight: "368px",
      maxHeight: "630px",
    },
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      boxShadow: "-4px 8px 16px rgba(0, 0, 0, 0.16)",
    },
  }));

  return (
    <>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 2,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={4}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label={"Selecione o mês"}
                  views={["month", "year"]}
                  value={filterDate}
                  onChange={(date) => handleFilterChange(date)}
                  sx={{ display: "flex", width: "100%" }}
                  required
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      >
                        Data
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFeedbacks.map((feedback) => {
                      return (
                        <TableRow
                          key={feedback.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {dayjs(feedback.data).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              onClick={() => {
                                viewFeedback(feedback.uuid);
                              }}
                              sx={{ ml: 1 }}
                            >
                              Visualizar
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Button
              sx={{ mt: 2 }}
              onClick={() => {
                navigate("/feedback");
              }}
            >
              Voltar
            </Button>
          </Grid>
        </Box>
      </Container>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContent>
          {feedback !== undefined && (
            <>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Feedback: {dayjs(feedback.data).format("DD/MM/YYYY")}
              </Typography>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Alimentação:</Typography>
                  <Typography variant="body2">
                    {feedback.alimentacao}
                  </Typography>
                  {feedback.alimentacao_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.alimentacao_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {feedback.dores.map((dor, index) => (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{ mt: index === 1 && 2 }}
                      >
                        Ausencia de dores:
                      </Typography>
                      <Typography variant="body2">
                        {dor.ausencia_dor}
                      </Typography>
                      <Typography variant="body2">
                        Descrição da dor: {dor.descricao}
                      </Typography>
                      {dor.ausencia_dor_obs !== null && (
                        <Typography variant="body2">
                          OBS: {dor.ausencia_dor_obs}
                        </Typography>
                      )}
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Autoestima:</Typography>
                  <Typography variant="body2">{feedback.autoestima}</Typography>
                  {feedback.autoestima_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.autoestima_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Disposição:</Typography>
                  <Typography variant="body2">{feedback.disposicao}</Typography>
                  {feedback.disposicao_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.disposicao_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Saúde:</Typography>
                  <Typography variant="body2">{feedback.doenca}</Typography>
                  <Typography variant="body2">
                    Descrição problema saúde: {feedback.descricao_doenca}
                  </Typography>
                  {feedback.doenca_obs !== null && (
                    <Typography variant="body2">
                      OBS: {feedback.doenca_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Motivação para treinar:
                  </Typography>
                  <Typography variant="body2">
                    {feedback.frequencia_motivacao}
                  </Typography>
                  {feedback.frequencia_motivacao_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.frequencia_motivacao_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Ingestão de água:</Typography>
                  <Typography variant="body2">
                    {feedback.ingestao_agua}
                  </Typography>
                  {feedback.ingestao_agua_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.ingestao_agua_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Ingestão de bebidas alcoolicas:
                  </Typography>
                  <Typography variant="body2">
                    {feedback.ingestao_bebida_alcoolica}
                  </Typography>
                  {feedback.ingestao_bebida_alcoolica_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.ingestao_bebida_alcoolica_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Intensidade nos treinos:
                  </Typography>
                  <Typography variant="body2">
                    {feedback.intensidade_treino}
                  </Typography>
                  {feedback.intensidade_treino_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.intensidade_treino_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Organização:</Typography>
                  <Typography variant="body2">
                    {feedback.organizacao}
                  </Typography>
                  {feedback.organizacao_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.organizacao_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Sono (horas):</Typography>
                  <Typography variant="body2">
                    {feedback.sono_quantitativo}
                  </Typography>
                  {feedback.sono_quantitativo_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.sono_quantitativo_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Sono (qualidade):</Typography>
                  <Typography variant="body2">
                    {feedback.sono_qualitativo}
                  </Typography>
                  {feedback.sono_qualitativo_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.sono_qualitativo_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Tabagismo:</Typography>
                  <Typography variant="body2">{feedback.tabagismo}</Typography>
                  {feedback.tabagismo_obs !== null && (
                    <Typography variant="body2">
                      OBS:
                      {feedback.tabagismo_obs}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleClose} variant="contained">
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </StyledDialog>
    </>
  );
}
