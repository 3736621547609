import { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Skeleton,
  TextField,
} from "@mui/material";
import educacaoService from "../educacaoService";
import Funcoes from "../../../composables/funcoes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import youTubeEmbed from "../../../components/VideoPlayer";

export default function DashboardUserView() {
  const { getCategoriaAluno } = educacaoService();
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const { Markdown } = Funcoes();

  useEffect(() => {
    async function getData() {
      try {
        const response = await getCategoriaAluno();
        // Converte o objeto de categorias em uma matriz de objetos
        const categoriasArray = Object.entries(response.data).map(
          ([categoria, videos]) => ({
            categoria,
            videos,
          })
        );
        setCategorias(categoriasArray);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
        {isLoading ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Skeleton variant="rectangular" height={150} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={50} />
          </div>
        ) : (
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Pesquisa"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12}>
              {searchTerm && (
                <Grid container spacing={3}>
                  {categorias
                    .flatMap((categoria) => categoria.videos)
                    .filter((video) =>
                      video.titulo
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((video) => (
                      <Grid item xs={12} key={video.uuid}>
                        <Typography variant="h4">{video.titulo}</Typography>
                        <Typography>{Markdown(video.descricao)}</Typography>
                        <div
                          style={{
                            overflow: "hidden",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          <iframe
                            title="Vídeo do YouTube"
                            style={{
                              borderRadius: "24px",
                              height: "300px",
                              width: "100%",
                            }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            src={youTubeEmbed(video.link)}
                            frameBorder="0"
                          ></iframe>
                        </div>
                        <Divider sx={{ mt: 3 }} />
                      </Grid>
                    ))}
                </Grid>
              )}
              {!searchTerm &&
                categorias.map((categoria, index) => {
                  const isLastAccordion = index === categorias.length - 1;
                  return (
                    <Accordion
                      defaultExpanded={index === 0}
                      key={index}
                      sx={{
                        width: "100%",
                        ...(isLastAccordion && { mb: 3 }),
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        color="text.secondary"
                        sx={{
                          fontWeight: 700,
                          opacity: "0.6",
                          fontSize: "20px",
                        }}
                      >
                        {categoria.categoria}
                      </AccordionSummary>
                      <AccordionDetails>
                        {categoria.videos.map((video) => (
                          <Grid key={video.uuid}>
                            <Grid item xs={12} mt={2} container spacing={2}>
                              <Grid
                                item
                                lg={6}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  gap: "30px",
                                }}
                              >
                                <div>
                                  <Typography variant="h4">
                                    {video.titulo}
                                  </Typography>
                                  <Typography>
                                    {Markdown(video.descricao)}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item lg={6} xs={12}>
                                <div
                                  style={{
                                    overflow: "hidden",
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <iframe
                                    title="Vídeo do YouTube"
                                    style={{
                                      borderRadius: "24px",
                                      height: "300px",
                                      width: "100%",
                                    }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    src={youTubeEmbed(video.link)}
                                    frameBorder="0"
                                  ></iframe>
                                </div>
                              </Grid>
                            </Grid>
                            <Divider sx={{ mt: 3 }} />
                          </Grid>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
}
