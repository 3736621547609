import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CssBaseline,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import anamneseService from "../anamneseService";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function FormPostUser() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { postAnamnese } = anamneseService();

  const handleSubmitPostAnamnese = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formatData = async () => {
      const partes = data.get("data_realizacao_ultimo_backup").split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      fk_uuid_aluno: uuid,
      endereco: data.get("endereco"),
      data_realizacao_ultimo_backup: await formatData(),
      data_realizacao_ultimo_backup_obs: data.get(
        "data_realizacao_ultimo_backup_obs"
      ),
      suplementacao: data.get("suplementacao"),
      patologias: data.get("patologias"),
      peso: data.get("peso"),
      idade: data.get("idade"),
      limitacoes: data.get("limitacoes"),
      limitacoes_obs: data.get("limitacoes_obs"),
      setor_atuacao: data.get("setor_atuacao"),
      objetivos_iniciais: data.get("objetivos_iniciais"),
      comprometimento_resultado: data.get("comprometimento_resultado"),
      solucao_musculacao: data.get("solucao_musculacao"),
      dificuldade_rotina_saudavel: data.get("dificuldade_rotina_saudavel"),
      meio_conhecimento_jp: data.get("meio_conhecimento_jp"),
      esforco_fisico_desejado: data.get("esforco_fisico_desejado"),
      fisico_desejado: data.get("fisico_desejado"),
      fisico_desejado_obs: data.get("fisico_desejado_obs"),
      estetica_corporal: data.get("estetica_corporal"),
      predominanca_trabalho: data.get("predominanca_trabalho"),
      tempo_musculacao: data.get("tempo_musculacao"),
      jornada_trabalho: data.get("jornada_trabalho"),
      alimentacao_diaria: data.get("alimentacao_diaria"),
      alimentacao_diaria_obs: data.get("alimentacao_diaria_obs"),
      drogas_ilicitas: data.get("drogas_ilicitas"),
      drogas_ilicitas_obs: data.get("drogas_ilicitas_obs"),
      rotina_trabalho: data.get("rotina_trabalho"),
      consome_bebida_alcoolicas: data.get("consome_bebida_alcoolicas"),
      consome_bebida_alcoolicas_obs: data.get("consome_bebida_alcoolicas_obs"),
      disposicao_diaria: data.get("disposicao_diaria"),
      disposicao_diaria_obs: data.get("disposicao_diaria_obs"),
      ingestao_agua: data.get("ingestao_agua"),
      historico_esportivo: data.get("historico_esportivo"),
      compulsao_alimentar: data.get("compulsao_alimentar"),
      compulsao_alimentar_obs: data.get("compulsao_alimentar_obs"),
      frequencia_exercicio_fisico: data.get("frequencia_exercicio_fisico"),
      frequencia_atividade_fisica: data.get("frequencia_atividade_fisica"),
      classificacao_alimentacao: data.get("classificacao_alimentacao"),
    };

    try {
      await postAnamnese(form);
      toast.success("Anamnese cadastrada com sucesso!")
      navigate(`/alunos/anamnese/${uuid}`);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        mt: 3,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmitPostAnamnese}
            sx={{ mt: 3 }}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="data_realizacao_ultimo_backup"
                  label="Realização ultimo BKP"
                  name="data_realizacao_ultimo_backup"
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99/99/9999" maskChar="_" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="data_realizacao_ultimo_backup_obs"
                  label="Observações BKP"
                  name="data_realizacao_ultimo_backup_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="suplementacao"
                  label="Suplementacao"
                  name="suplementacao"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="patologias"
                  label="Patologias"
                  name="patologias"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="peso"
                  label="Peso"
                  name="peso"
                  placeholder="75.9"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField fullWidth id="idade" label="Idade" name="idade" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="limitacoes">Tem limitações?</InputLabel>
                  <Select
                    labelId="limitacoes"
                    id="limitacoes"
                    sx={{ width: "100%" }}
                    name="limitacoes"
                  >
                    <MenuItem value={"nao"}>Não</MenuItem>
                    <MenuItem value={"sim"}>Sim</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="limitacoes_obs"
                  label="Observações limitações"
                  name="limitacoes_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="setor_atuacao"
                  label="Setor de atuação"
                  name="setor_atuacao"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="objetivos_iniciais"
                  label="Objetivos iniciais"
                  name="objetivos_iniciais"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="comprometimento_resultado"
                  label="Comprometimento com o resultado"
                  name="comprometimento_resultado"
                  placeholder="0 a 10"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="solucao_musculacao"
                  label="Musculação é a solução?"
                  name="solucao_musculacao"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="dificuldade_rotina_saudavel"
                  label="Dificuldades de uma rotina saudavel"
                  name="dificuldade_rotina_saudavel"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="meio_conhecimento_jp"
                  label="Meio de conhecimento JP"
                  name="meio_conhecimento_jp"
                  placeholder="Instagram, facebook e etc..."
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="esforco_fisico_desejado"
                  label="Esforço fisico desejado"
                  name="esforco_fisico_desejado"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="fisico_desejado"
                  label="Fisico desejado"
                  name="fisico_desejado"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="fisico_desejado_obs"
                  label="Observações fisico desejado"
                  name="fisico_desejado_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="estetica_corporal"
                  label="Estetica corporal"
                  name="estetica_corporal"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="predominanca_trabalho"
                  label="Predominancia de posição no trabalho"
                  name="predominanca_trabalho"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="tempo_musculacao"
                  label="Tempo que ja treina?"
                  name="tempo_musculacao"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="jornada_trabalho"
                  label="Jornada de trabalho"
                  name="jornada_trabalho"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="alimentacao_diaria"
                  label="Alimentação diária"
                  name="alimentacao_diaria"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="alimentacao_diaria_obs"
                  label="Observações alimentação diária"
                  name="alimentacao_diaria_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="drogas_ilicitas"
                  label="Uso de drogas ilicitas"
                  name="drogas_ilicitas"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="drogas_ilicitas_obs"
                  label="Observações uso de drogas ilicitas"
                  name="drogas_ilicitas_obs"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="rotina_trabalho"
                  label="Descreva a rotina de trabalho"
                  name="rotina_trabalho"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="consome_bebida_alcoolicas"
                  label="Uso de bebidas alcoolicas"
                  name="consome_bebida_alcoolicas"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="consome_bebida_alcoolicas_obs"
                  label="Observações uso de bebidas alcoolicas"
                  name="consome_bebida_alcoolicas_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="disposicao_diaria"
                  label="Disposição diária"
                  name="disposicao_diaria"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="disposicao_diaria_obs"
                  label="Observações da disposição diária"
                  name="disposicao_diaria_obs"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="ingestao_agua"
                  label="Ingestão de água"
                  name="ingestao_agua"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="historico_esportivo"
                  label="Histórico esportivo"
                  name="historico_esportivo"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="frequencia_exercicio_fisico"
                  label="Frequência de exercicio fisico"
                  name="frequencia_exercicio_fisico"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="frequencia_atividade_fisica"
                  label="Frequência de atividade fisica"
                  name="frequencia_atividade_fisica"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="compulsao_alimentar"
                  label="Compulsão alimentar"
                  name="compulsao_alimentar"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="compulsao_alimentar_obs"
                  label="Observações da compulsão alimentar"
                  name="compulsao_alimentar_obs"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="classificacao_alimentacao"
                  label="Classificação alimentar"
                  name="classificacao_alimentacao"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} mt={3} mb={3}>
              <Button
                sx={{ marginRight: "8px" }}
                onClick={() => {
                  navigate(`/alunos/anamnese/${uuid}`);
                }}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Gravar
              </Button>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
