import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getAlunos = async () => {
    const { data } = await api.get("/portal/aluno", { headers });
    return data;
  };

  const returnGraphic = async (uuid, item, competencia) => {
    const { data } = await api.get(
      `/portal/grafico/${uuid}/${item}/${competencia}`,
      { headers }
    );
    return data;
  };
  return {
    getAlunos,
    returnGraphic,
  };
}
