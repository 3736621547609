import { api } from "../../boot/axios";
import UseApi from "../../composables/UseApi";

export default function exampleService() {
  const { csrfCookie } = UseApi();

  const post = async (form) => {
    await csrfCookie();

    const { data } = await api.post("/primeiro-acesso/gera-otp", form);
    return data;
  };

  const checkCode = async (form) => {
    await csrfCookie();

    const { data } = await api.post("/primeiro-acesso/verifica-otp", form);
    return data;
  };

  const createPassword = async (form) => {
    await csrfCookie();

    const { data } = await api.post("/primeiro-acesso/criar-senha", form);
    return data;
  };

  return {
    post,
    checkCode,
    createPassword,
  };
}
