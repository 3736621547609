import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const postUser = async (form) => {
    const { data } = await api.post("/portal/usuario", form, { headers });
    return data;
  };

  const editUser = async (form, uuid) => {
    const { data } = await api.put(`/portal/usuario/${uuid}`, form, {
      headers,
    });
    return data;
  };

  const getUsers = async () => {
    const { data } = await api.get("/portal/usuario", { headers });
    return data;
  };

  const showUser = async (uuid) => {
    const { data } = await api.get(`/portal/usuario/${uuid}`, { headers });
    return data;
  };

  const postAluno = async (form) => {
    const { data } = await api.post("/portal/aluno", form, { headers });
    return data;
  };

  const editAluno = async (form, uuid) => {
    const { data } = await api.put(`/portal/usuario/${uuid}`, form, {
      headers,
    });
    return data;
  };

  const getAlunos = async () => {
    const { data } = await api.get("/portal/aluno", { headers });
    return data;
  };

  const showAluno = async (uuid) => {
    const { data } = await api.get(`/portal/aluno/${uuid}`, { headers });
    return data;
  };

  const deleteAluno = async (uuid) => {
    const { data } = await api.delete(`/portal/aluno/${uuid}`, {
      headers,
    });
    return data;
  };

  return {
    postUser,
    editUser,
    getUsers,
    showUser,
    postAluno,
    editAluno,
    getAlunos,
    showAluno,
    deleteAluno,
  };
}
