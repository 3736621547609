import Rotas from "../src/router/routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    async function verificaServiceWorker() {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.log("User Permission Denied.");
        return;
      }
      if ("serviceWorker" in navigator) {
        await navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            console.log(
              "Service Worker registered with scope:",
              registration.scope
            );
          })
          .catch((err) => {
            console.log("Service Worker registration failed:", err);
          });
      }
    }

    verificaServiceWorker();
  }, []);
  return (
    <>
      <Rotas />
      <ToastContainer autoClose={3000} />
    </>
  );
}
