// middleware.js
import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const isAuthenticated = () => {
  const logged = Cookies.get("logged");

  return Boolean(logged);
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};
const VerifyLogged = ({ children }) => {
  return !isAuthenticated() ? children : <Navigate to="/" />;
};

const FuncRoute = ({ children, func }) => {
  return isAuthenticated() && func ? children : <Navigate to="/" />;
};

export { PrivateRoute, VerifyLogged, FuncRoute };
