import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Container,
  Box,
  CssBaseline,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import graficosService from "../graficosService";
import { renderizarGrafico } from "./renderizaGrafico";
import { renderizaMedias } from "./renderizaMedia";

const defaultTheme = createTheme();

export default function Dashboard() {
  const { getAlunos, returnGraphic } = graficosService();
  const [alunos, setAlunos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const itensGrafico = [
    "Sono qualitativo",
    "Sono quantitativo",
    "Alimentação",
    "Frequencia motivação",
    "Autoestima",
    "Disposição",
    "Ingestão água",
    "Ingestão bebida alcoólica",
    "Intensidade treino",
    "Organização",
    "Tabagismo",
    "Dores",
    "Doenças",
  ];
  const [alunoSelecionado, setAlunoSelecionado] = useState(null);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [dataSelecionada, setDataSelecionda] = useState(null);
  const [exibeMedia, setExibeMedia] = useState(false);
  const [dadosGrafico, setDadosGrafico] = useState([]);
  const [semanasGrafico, setSemanasGrafico] = useState([]);
  const [nomeItem, setNomeItem] = useState([]);
  const [dadosMedia, setDadosMedia] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAlunos();
        setAlunos(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os alunos:", error);
      }
    };

    fetchData();
  }, []);

  const handleAlunoSelecionado = (event) => {
    const uuid = event.target.value;
    const aluno = alunos.find((aluno) => aluno.uuid === uuid);
    setAlunoSelecionado(aluno);
  };

  const handleItemGrafico = (event) => {
    setItemSelecionado(event.target.value);
  };

  const handleDateChange = (date) => {
    setDataSelecionda(date);
  };

  const formatarValorSelecionado = (valor) => {
    // Remove apenas os caracteres de pontuação
    let valorFormatado = valor.replace(/[^\w\sÀ-ÿÇç]/gi, "");
    // Remove os acentos
    valorFormatado = valorFormatado
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    // Converte todas as letras para minúsculas
    valorFormatado = valorFormatado.toLowerCase();
    if (valorFormatado.includes("media")) {
      return (valorFormatado =
        valorFormatado
          .replace(/[^\w\sÀ-ÿÇç]|media/gi, "") // Remove caracteres não alfanuméricos e "media"
          .trim() // Remove espaços em branco extras no início e no final
          .replace(/\s+/g, "-") + // Substitui espaços em branco por traços
        "/media");
    }
    // Substitui espaços por hífens
    valorFormatado = valorFormatado.replace(/\s+/g, "-");
    return valorFormatado;
  };

  const gerarGrafico = async () => {
    setExibeMedia(false);
    try {
      const response = await returnGraphic(
        alunoSelecionado.uuid,
        formatarValorSelecionado(itemSelecionado),
        dayjs(dataSelecionada).format("YYYY-MM")
      );
      setDadosGrafico(response.data.data);
      setSemanasGrafico(response.data.label);
      setNomeItem(response.data.descricao);
    } catch (error) {}
  };

  const gerarMedias = async () => {
    if (alunoSelecionado.uuid && dataSelecionada) {
      try {
        const responseMedia = await returnGraphic(
          alunoSelecionado.uuid,
          "medias",
          dayjs(dataSelecionada).format("YYYY-MM")
        );
        setDadosMedia(responseMedia.data);
        setExibeMedia(true);
      } catch (error) {
        console.error("Erro ao buscar as médias:", error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selecione um aluno
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Selecione um aluno"
                  onChange={handleAlunoSelecionado}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {alunos.map((aluno) => (
                    <MenuItem value={aluno.uuid} key={aluno.uuid}>
                      {aluno.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label={"Selecione o mês"}
                  views={["month", "year"]}
                  value={dataSelecionada}
                  onChange={handleDateChange}
                  sx={{ display: "flex", width: "100%" }}
                  required
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selecione um item do gráfico
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Selecione um item do gráfico"
                  onChange={handleItemGrafico}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {itensGrafico.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {alunoSelecionado && dataSelecionada && (
              <Grid item xs={6} md={2} sx={{ display: "flex", width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    if (alunoSelecionado && dataSelecionada) {
                      gerarMedias();
                    }
                  }}
                >
                  Gerar médias
                </Button>
              </Grid>
            )}
            {alunoSelecionado && dataSelecionada && itemSelecionado && (
              <Grid item xs={6} md={2} sx={{ display: "flex", width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    if (
                      alunoSelecionado &&
                      itemSelecionado &&
                      dataSelecionada
                    ) {
                      gerarGrafico();
                    }
                  }}
                >
                  Gerar gráfico
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              {exibeMedia ? (
                <Grid item xs={12}>
                  {renderizaMedias(dadosMedia)}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#212B2F",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      lineHeight: "2",
                    }}
                  >
                    {itemSelecionado}
                  </Typography>

                  {renderizarGrafico(
                    itemSelecionado,
                    dadosGrafico,
                    semanasGrafico,
                    nomeItem
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
