import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getAlunos = async () => {
    const { data } = await api.get("/portal/aluno", { headers });
    return data;
  };

  const getCalendario = async (todos, uuid, competencia) => {
    try {
      let url = "/portal/evento?";
      if (todos) url += `todos=${todos}&`;
      if (uuid) url += `fk_uuid_aluno=${uuid}&`;
      if (competencia) url += `competencia=${competencia}&`;

      const response = await api.get(url, { headers });

      const eventos = response.data.data.map((evento) => ({
        title: evento.titulo,
        date: evento.data,
        uuid: evento.uuid,
        backgroundColor: evento.cor,
        status: evento.status,
        atividade_fisica: evento.atividade_fisica,
        data_aluno: evento.data_aluno,
      }));

      return eventos;
    } catch (error) {
      console.error("Erro ao buscar o calendario api:", error);
      throw error;
    }
  };
  const getCalendarioTodos = async (competencia) => {
    try {
      let url = "/portal/evento?todos=true&";
      if (competencia) url += `competencia=${competencia}&`;

      const response = await api.get(url, { headers });

      const eventos = response.data.data.map((evento) => ({
        title: evento.titulo,
        date: evento.data,
        uuid: evento.uuid,
        backgroundColor: evento.cor,
        status: evento.status,
        atividade_fisica: evento.atividade_fisica,
        todos: evento.todos,
        data_aluno: evento.data_aluno,
      }));

      return eventos;
    } catch (error) {
      console.error("Erro ao buscar o calendario api:", error);
      throw error;
    }
  };

  const showCalendario = async (uuid) => {
    const { data } = await api.get(`portal/evento/${uuid}`, { headers });
    return data;
  };

  const postEvento = async (form) => {
    const { data } = await api.post("portal/evento", form, { headers });
    return data;
  };

  const putEvento = async (uuid, form) => {
    const { data } = await api.put(`portal/evento/${uuid}`, form, { headers });
    return data;
  };

  const deleteEvento = async (uuid) => {
    const { data } = await api.delete(`portal/evento/${uuid}`, { headers });
    return data;
  };

  const getAtvFisica = async () => {
    const { data } = await api.get("portal/atividades-fisicas", {
      headers,
    });
    return data;
  };

  const postAtvFisica = async (form) => {
    const { data } = await api.post("portal/atividades-fisicas", form, {
      headers,
    });
    return data;
  };

  const deleteAtvFisica = async (uuid) => {
    const { data } = await api.delete(`portal/atividades-fisicas/${uuid}`, {
      headers,
    });
    return data;
  };

  const getContador = async (uuid, competencia) => {
    const { data } = await api.get(
      `portal/evento/contador/${uuid}/competencia/${competencia}`,
      {
        headers,
      }
    );
    return data;
  };

  return {
    getAlunos,
    getCalendario,
    getCalendarioTodos,
    showCalendario,
    postEvento,
    putEvento,
    deleteEvento,
    getAtvFisica,
    postAtvFisica,
    deleteAtvFisica,
    getContador,
  };
}
