import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
	apiKey: "AIzaSyCtHfGMDi9nUOzej_wcM_k_pJxHN8zSGV8",
	authDomain: "teste-72dad.firebaseapp.com",
	projectId: "teste-72dad",
	storageBucket: "teste-72dad.appspot.com",
	messagingSenderId: "752639536399",
	appId: "1:752639536399:web:4720b79e962208b51a227a",
	measurementId: "G-Q28L1LCD4H",
});

const messaging = getMessaging(firebaseApp);

export { messaging };
