import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  CssBaseline,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  ButtonGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import funcService from "../funcService";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

const handleSubmit = (event) => {
  event.preventDefault();
};

export default function Funcionarios() {
  const { getFunc, deleteFunc } = funcService();
  const [originalFuncs, setOriginalFuncs] = useState([]);
  const navigate = useNavigate();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [filter, setFilter] = useState({
    nome: "",
    telefone: "",
    email: "",
  });

  useEffect(() => {
    async function getData() {
      try {
        const response = await getFunc();
        setOriginalFuncs(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const removeFunc = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o(a) funcionário(a)?"
    );

    if (confirmRemoval) {
      try {
        await deleteFunc(uuid);
        toast.success("Funcionário(a) removido(a) com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const editFunc = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja editar o(a) funcionário(a)?"
    );

    if (confirmRemoval) {
      try {
        navigate(`/funcionarios/cadastro/${uuid}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate("/funcionarios/cadastro");
                }}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#1976D2" }}
                >
                  <Typography style={{ color: "#fff" }}>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoComplete="nome"
                            value={filter.nome}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="telefone"
                            label="Telefone"
                            name="telefone"
                            autoComplete="telefone"
                            value={filter.telefone}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            value={filter.email}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Telefone
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {originalFuncs
                      .filter((func) => {
                        return (
                          func.nome
                            .toLowerCase()
                            .includes(filter.nome.toLowerCase()) &&
                          func.telefone.includes(filter.telefone) &&
                          func.email
                            .toLowerCase()
                            .includes(filter.email.toLowerCase())
                        );
                      })
                      .map((i) => (
                        <TableRow
                          key={i.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {i.nome}
                          </TableCell>
                          <TableCell align="right">{i.telefone}</TableCell>
                          <TableCell align="right">{i.email}</TableCell>
                          <TableCell align="right">
                            <ButtonGroup variant="contained" size="large">
                              <Button
                                startIcon={<EditIcon />}
                                onClick={() => {
                                  editFunc(i.uuid);
                                }}
                                title="Editar"
                              ></Button>
                              <Button
                                startIcon={<RemoveCircleIcon />}
                                onClick={() => {
                                  removeFunc(i.uuid);
                                }}
                                title="Remover"
                              ></Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
