import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import calendarioService from "../calendarioService";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Skeleton,
  Modal,
  Box,
  Container,
  CssBaseline,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const defaultTheme = createTheme();

export default function DashboardUserView({ userUuid }) {
  const { getCalendario, getCalendarioTodos } = calendarioService();
  const [eventos, setEventos] = useState([]);
  const [mesAtual, setMesAtual] = useState(new Date());
  const [uuidEvento, setuuidEvento] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedDate = `${mesAtual.getFullYear()}-${(
          mesAtual.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}`;

        const response = await getCalendario(false, userUuid, formattedDate);
        setEventos(response);

        const responseTodos = await getCalendarioTodos(formattedDate);
        setEventos((prevEventos) => {
          // Filtrar eventos já existentes e evitar duplicacao
          const eventosIds = prevEventos.map((evento) => evento.uuid);
          const novosEventos = response.filter(
            (evento) => !eventosIds.includes(evento.uuid)
          );
          const eventosTodosIds = prevEventos.map((evento) => evento.uuid);
          const novosEventosTodos = responseTodos.filter(
            (evento) => !eventosTodosIds.includes(evento.uuid)
          );
          // Adicionar eventos novos
          return [...prevEventos, ...novosEventos, ...novosEventosTodos];
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar o calendario:", error);
      }
    };

    fetchData();
  }, [mesAtual, updateFlag]);

  const handleEventClicked = async (uuid) => {
    setEditingCalendario(true);
    setuuidEvento(uuid);
    handleOpenModal();
  };

  const handlePrevMonth = () => {
    setMesAtual(new Date(mesAtual.getFullYear(), mesAtual.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setMesAtual(new Date(mesAtual.getFullYear(), mesAtual.getMonth() + 1));
  };

  // Evento
  const { postEvento, putEvento, deleteEvento, showCalendario, getAtvFisica } =
    calendarioService();
  const [formValues, setFormValues] = useState({
    uuid: "",
    data: "",
    titulo: "",
    fk_uuid_status: "",
    fk_uuid_atividade_fisica: "",
    data_inicio: "",
    data_fim: "",
    dias_semana: [],
    status: "",
    cor: "",
  });
  const [editingCalendario, setEditingCalendario] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [recorrencia, setRecorrencia] = useState(false);

  const handleCheckboxRecorrenciaChange = (event) => {
    setRecorrencia(event.target.checked);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setEditingCalendario(false);
    setuuidEvento();
    setOpenModal(false);
    setAtividadeFisicaSelecionada();
    setRecorrencia(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  const handleTituloChange = (event) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      titulo: event.target.value,
    }));
  };

  const handleDateChange = (date) => {
    const dateObject = date.toDate();
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      data: dateObject,
    }));
  };

  const handleDataInicioChange = (date) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      data_inicio: date.format("YYYY-MM-DD"), // Armazena a data de início no formato 'YYYY-MM-DD'
    }));
  };

  const handleDataTerminoChange = (date) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      data_fim: date.format("YYYY-MM-DD"), // Armazena a data de término no formato 'YYYY-MM-DD'
    }));
  };

  const handleRecorrenciaChange = (event) => {
    const selectedDays = event.target.value; // Obtém os dias da semana selecionados
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      dias_semana: selectedDays, // Armazena os dias da semana selecionados
    }));
  };

  const handleSubmit = async () => {
    const form = {
      data:
        !recorrencia && formValues.data
          ? formValues.data.toISOString().split("T")[0]
          : "",
      titulo: formValues.titulo,
      fk_uuid_aluno: userUuid,
      fk_uuid_status: "",
      fk_uuid_atividade_fisica:
        atividadeFisicaSelecionada !== null ? atividadeFisicaSelecionada : "",
      todos: false,
      evento_aluno: true,
      data_inicio: formValues.data_inicio,
      data_fim: formValues.data_fim,
      dias_semana: formValues.dias_semana,
    };

    try {
      await postEvento(form);
      toast.success("Evento criado com sucesso!");
      setUpdateFlag((prevFlag) => !prevFlag);
      handleCloseModal();
      setEditingCalendario(false);
      setAtividadeFisicaSelecionada("");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleEdit = async () => {
    const data =
      typeof formValues.data === "string"
        ? new Date(formValues.data)
        : formValues.data;
    const dataFormatada = data.toISOString().split("T")[0];

    const form = {
      uuid: uuidEvento,
      data: !recorrencia ? dataFormatada : "",
      titulo: formValues.titulo,
      fk_uuid_aluno: userUuid,
      fk_uuid_status: "",
      fk_uuid_atividade_fisica:
        atividadeFisicaSelecionada !== null ? atividadeFisicaSelecionada : "",
      todos: false,
      evento_aluno: true,
      data_inicio: formValues.data_inicio,
      data_fim: formValues.data_fim,
      dias_semana: formValues.dias_semana,
    };

    try {
      await putEvento(uuidEvento, form);
      setUpdateFlag((prevFlag) => !prevFlag);
      toast.success("Informações editadas com sucesso!");
      handleCloseModal();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleDelete = async (uuidEvento) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o evento?"
    );

    if (confirmRemoval) {
      try {
        await deleteEvento(uuidEvento);
        toast.success("Evento removido com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
        setEditingCalendario(false);
        handleCloseModal();
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (openModal && uuidEvento) {
      const fetchData = async () => {
        try {
          const response = await showCalendario(uuidEvento);
          setEditingCalendario(true);
          const {
            cor,
            data,
            titulo,
            fk_uuid_status,
            status,
            uuid,
            fk_uuid_atividade_fisica,
            data_inicio,
            data_fim,
            dias_semana,
          } = response;
          setFormValues({
            ...formValues,
            cor,
            data,
            titulo,
            fk_uuid_status,
            fk_uuid_atividade_fisica,
            status,
            uuid,
            data_inicio,
            data_fim,
            dias_semana,
          });
          if (fk_uuid_atividade_fisica) {
            setAtividadeFisicaSelecionada(fk_uuid_atividade_fisica);
          }
        } catch (error) {
          console.error("Erro ao buscar os detalhes do evento:", error);
        }
      };
      fetchData();
    } else {
      setFormValues({
        uuid: "",
        data: "",
        titulo: "",
        fk_uuid_aluno: "",
        fk_uuid_status: "",
        status: "",
        cor: "",
        todos: "",
        data_inicio: "",
        data_fim: "",
        dias_semana: [],
      });
      setEditingCalendario(false);
    }
  }, [openModal, uuidEvento]);

  // Atividades fisicas

  const [atividadesFisicas, setAtividadesFisicas] = useState([]);
  const [atividadeFisicaSelecionada, setAtividadeFisicaSelecionada] =
    useState("");
  const handleAtividadeFisicaSelecionada = (event) => {
    setAtividadeFisicaSelecionada(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAtv = await getAtvFisica();
        setAtividadesFisicas(responseAtv.data);
        setEditingCalendario(false);
      } catch (error) {
        console.error("Erro ao buscar os status:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenModal();
                }}
              >
                Criar evento
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#212B2F",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                  lineHeight: "1.5",
                }}
              >
                {mesAtual
                  .toLocaleString("default", { month: "long" })
                  .charAt(0)
                  .toUpperCase() +
                  mesAtual
                    .toLocaleString("default", { month: "long" })
                    .slice(1)}
                {" / "}
                {mesAtual.getFullYear()}
              </Typography>
              <div style={{ display: "flex", gap: "8px" }}>
                <Button onClick={handlePrevMonth} variant="contained">
                  {"<"}
                </Button>
                <Button onClick={handleNextMonth} variant="contained">
                  {">"}
                </Button>
              </div>
            </Grid>
            {isLoading ? (
              <Grid item xs={12}>
                <Skeleton variant="rounded" width={"100%"} height={80} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                {eventos.length > 0 ? (
                  eventos
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((evento) => {
                      return (
                        <Card
                          sx={{
                            borderLeft: `10px solid ${
                              evento.backgroundColor == null
                                ? "#1976d2"
                                : evento.backgroundColor
                            }`,
                            marginBottom: 3,
                            borderRadius: "16px",
                          }}
                        >
                          <CardContent>
                            <Typography color="textSecondary">
                              {evento.data_aluno}
                            </Typography>
                            <Typography variant="h6" component="h2">
                              {evento.title}
                            </Typography>
                            <Typography variant="overline" component="h2">
                              {evento.atividade_fisica !== null &&
                                `Atividade Fisica: ${evento.atividade_fisica}`}
                            </Typography>
                            <Typography
                              variant="overline"
                              component="h2"
                              color={evento.backgroundColor}
                            >
                              {evento.status !== null &&
                                `Status: ${evento.status}`}
                            </Typography>

                            {evento.todos !== 1 &&
                              evento.status === null &&
                              evento.atividade_fisica === null && (
                                <Button
                                  onClick={() => {
                                    handleEventClicked(evento.uuid);
                                  }}
                                >
                                  Editar / Excluir
                                </Button>
                              )}
                          </CardContent>
                        </Card>
                      );
                    })
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#212B2F",
                      fontWeight: "700",
                      fontSize: "1.125rem",
                      lineHeight: "1.5",
                      textAlign: "center",
                      mt: 3,
                    }}
                  >
                    Nenhum evento cadastrado nesse mês
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recorrencia}
                      onChange={handleCheckboxRecorrenciaChange}
                      color="primary"
                    />
                  }
                  label="Recorrência"
                />
              </Grid>
              {recorrencia ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="Data de Início"
                          value={
                            formValues.data_inicio
                              ? dayjs(formValues.data_inicio)
                              : null
                          }
                          onChange={handleDataInicioChange}
                          sx={{ width: "100%" }}
                        />

                        <DatePicker
                          label="Data de Término"
                          value={
                            formValues.data_fim
                              ? dayjs(formValues.data_fim)
                              : null
                          }
                          onChange={handleDataTerminoChange}
                          sx={{ width: "100%" }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      mt: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-recorrencia">
                        Selecione os dias da semana
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-recorrencia"
                        id="demo-simple-select-recorrencia"
                        label="Selecione os dias da semana"
                        onChange={handleRecorrenciaChange}
                        value={formValues.dias_semana || []}
                        multiple
                      >
                        <MenuItem value={0}>Domingo</MenuItem>
                        <MenuItem value={1}>Segunda-feira</MenuItem>
                        <MenuItem value={2}>Terça-feira</MenuItem>
                        <MenuItem value={3}>Quarta-feira</MenuItem>
                        <MenuItem value={4}>Quinta-feira</MenuItem>
                        <MenuItem value={5}>Sexta-feira</MenuItem>
                        <MenuItem value={6}>Sábado</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <DatePicker
                        label="Selecione a data"
                        value={formValues.data ? dayjs(formValues.data) : null}
                        onChange={handleDateChange}
                        sx={{ width: "100%" }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  value={formValues.titulo}
                  onChange={handleTituloChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-atividade">
                    Selecione a atividade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-atividade"
                    id="demo-simple-select-atividade"
                    label="Selecione a atividade"
                    onChange={handleAtividadeFisicaSelecionada}
                    value={atividadeFisicaSelecionada || ""}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {atividadesFisicas.map((atv) => (
                      <MenuItem value={atv.uuid} key={atv.uuid}>
                        {atv.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                {editingCalendario ? (
                  <Button
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                  >
                    Gravar
                  </Button>
                )}
                {editingCalendario && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mt: 3 }}
                    onClick={() => {
                      handleDelete(uuidEvento);
                    }}
                  >
                    Excluir
                  </Button>
                )}
                <Button sx={{ mt: 3 }} onClick={handleCloseModal}>
                  Fechar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
