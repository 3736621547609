import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  FormControl,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Container,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import siginService from "./signinService";
import Cookies from "js-cookie";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../redux/action";
import { toast } from "react-toastify";
import Logo from "../../assets/logo.png";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../src/firebase";

const defaultTheme = createTheme();

export default function SignIn() {
  const { post, postNotification } = siginService();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setRememberPassword(data.get("remember") === "on" ? true : false);
    const form = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      const response = await post(form);
      getFcmToken(response.data.uuid);
      setInfoUser(response);
      Cookies.set("jwt", response.data.token);
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const setInfoUser = (data) => {
    Cookies.set("logged", true, { path: "/", expires: rememberPassword && 30 });
    dispatch(updateUserData(data.data));
  };

  async function getFcmToken(uuid) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BM-TiA0mnj9NoiLR2uwfnAVPbLqvVHNHfXSSP8I1ge30y-Za1fMYR2l6gwuDNcbAau-I0kcWm78TTno1iYzbx-g",
      });
      if (currentToken) {
        console.log("Token fetched successfully:", currentToken);
        postNotification(currentToken, uuid);
      } else {
        console.warn(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (err) {
      console.error("An error occurred while retrieving token. ", err);
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <img src={Logo} alt="Logo" style={{ width: "128px" }} />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Senha</InputLabel>
                  <OutlinedInput
                    required
                    name="password"
                    label="Senha"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Lembrar senha"
                  name="remember"
                  id="remember"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Acessar
            </Button>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Link href="/primeiro-acesso" variant="body2">
                  Primeiro acesso?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/esqueci-minha-senha" variant="body2">
                  Esqueci minha senha
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
