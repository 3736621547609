import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getStatus = async () => {
    const { data } = await api.get("/portal/status", { headers });
    return data;
  };
  const showStatus = async (uuid) => {
    const { data } = await api.get(`/portal/status/${uuid}`, { headers });
    return data;
  };
  const postStatus = async (form) => {
    const { data } = await api.post("/portal/status", form, { headers });
    return data;
  };
  const deleteStatus = async (uuid) => {
    const { data } = await api.delete(`/portal/status/${uuid}`, { headers });
    return data;
  };

  return {
    getStatus,
    showStatus,
    postStatus,
    deleteStatus,
  };
}
