import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn";
import FirstAcess from "../pages/FirstAcess/generateOtp";
import FirtsAccessCode from "../pages/FirstAcess/firtsAccessCode";
import CreatePassword from "../pages/FirstAcess/createPassWord";
import ForgotPassword from "../pages/ForgotPassword/forgotPassword";
import Home from "../pages/Home";
import ResetPassword from "../pages/ForgotPassword/resetPassword";
import { PrivateRoute, VerifyLogged, FuncRoute } from "./middleware";
import Funcionarios from "../pages/Funcs/Pages";
import Alunos from "../pages/Alunos/Pages";
import PutFunc from "../pages/Funcs/Pages/putFunc";
import PostFunc from "../pages/Funcs/Pages/postFunc";
import PostAluno from "../pages/Alunos/Pages/postAluno";
import PutAluno from "../pages/Alunos/Pages/putAluno";
import Arquivos from "../pages/Arquivos/Pages";
import Feedback from "../pages/Feedback/Pages";
import FormFeedback from "../pages/Feedback/Pages/formFeedback";
import Educacao from "../pages/Educacao/Pages";
import Calendario from "../pages/Calendario";
import Page404 from "../pages/NotFound";
import Avisos from "../pages/Avisos/Pages";
import Graficos from "../pages/Graficos";
import ShowFeedbackAluno from "../pages/Feedback/Pages/showFeedback";
import Anamnese from "../pages/Anamnese/Pages";
import PostAlunoAnamnese from "../pages/Anamnese/Pages/postAluno";
import PutAlunoAnamnese from "../pages/Anamnese/Pages/putAluno";
import { useSelector } from "react-redux";

function Rotas() {
  const userData = useSelector((state) => state.userData);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <VerifyLogged>
              <SignIn />
            </VerifyLogged>
          }
        />
        <Route
          path="/primeiro-acesso"
          element={
            <VerifyLogged>
              <FirstAcess />
            </VerifyLogged>
          }
        />
        <Route
          path="/primeiro-acesso/:uuid"
          element={
            <VerifyLogged>
              <FirtsAccessCode />
            </VerifyLogged>
          }
        />
        <Route
          path="/primeiro-acesso/:uuid/:otp"
          element={
            <VerifyLogged>
              <CreatePassword />
            </VerifyLogged>
          }
        />
        <Route
          path="/esqueci-minha-senha"
          element={
            <VerifyLogged>
              <ForgotPassword />
            </VerifyLogged>
          }
        />
        <Route
          path="/esqueci-minha-senha/:token"
          element={
            <VerifyLogged>
              <ResetPassword />
            </VerifyLogged>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/funcionarios"
          element={
            <FuncRoute func={userData?.funcionario}>
              <Funcionarios />
            </FuncRoute>
          }
        />
        <Route
          path="/funcionarios/cadastro"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PostFunc />
            </FuncRoute>
          }
        />
        <Route
          path="/funcionarios/cadastro/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PutFunc />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos"
          element={
            <FuncRoute func={userData?.funcionario}>
              <Alunos />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos/cadastro"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PostAluno />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos/cadastro/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PutAluno />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos/anamnese/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <Anamnese />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos/cadastro/anamnese/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PostAlunoAnamnese />
            </FuncRoute>
          }
        />
        <Route
          path="/alunos/anamnese/:useruuid/edit/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <PutAlunoAnamnese />
            </FuncRoute>
          }
        />
        <Route
          path="/arquivos/:uuid"
          element={
            <PrivateRoute>
              <Arquivos />
            </PrivateRoute>
          }
        />
        <Route
          path="/feedback/"
          element={
            <FuncRoute func={userData?.funcionario}>
              <Feedback />
            </FuncRoute>
          }
        />
        <Route
          path="/feedback/:uuid"
          element={
            <PrivateRoute>
              <FormFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/feedback/view/:uuid"
          element={
            <FuncRoute func={userData?.funcionario}>
              <ShowFeedbackAluno />
            </FuncRoute>
          }
        />
        <Route
          path="/educacional"
          element={
            <PrivateRoute>
              <Educacao />
            </PrivateRoute>
          }
        />
        <Route
          path="/calendario"
          element={
            <PrivateRoute>
              <Calendario />
            </PrivateRoute>
          }
        />
        <Route
          path="/avisos/:uuid"
          element={
            <PrivateRoute>
              <Avisos />
            </PrivateRoute>
          }
        />

        <Route
          path="/graficos"
          element={
            <FuncRoute func={userData?.funcionario}>
              <Graficos />
            </FuncRoute>
          }
        />

        {/* ROTA 404*/}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Rotas;
