import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  Skeleton,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import avisosService from "../avisosService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const defaultTheme = createTheme();

export default function UserViewAvisos() {
  const { getAvisos, deleteAviso, getFuncionarios, postAviso } =
    avisosService();
  const [avisos, setAvisos] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [funcionarios, setFuncionarios] = useState([]);
  const [funcSelecionado, setFuncSelecionado] = useState();
  const [message, setMessage] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const userData = useSelector((state) => state.userData);
  const { uuid } = useParams();

  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAvisos(uuid);
        setAvisos(response.data);
        const responseFuncionarios = await getFuncionarios();
        setFuncionarios(responseFuncionarios.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const removeAviso = async (uuid) => {
    try {
      await deleteAviso(uuid);
      toast.success("Mensagem removida com sucesso!");
      setUpdateFlag(!updateFlag);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const submitMessage = async () => {
    const form = {
      descricao: message,
      fk_uuid_destinatario: funcSelecionado,
      fk_uuid_remetente: uuid,
    };

    try {
      await postAviso(form);
      toast.success("Mensagem enviada com sucesso!");
      handleModal();
      setMessage();
      setFuncSelecionado();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  const handleFuncionarioSelecionado = (event) => {
    setFuncSelecionado(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setButtonDisabled(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid container>
            {!userData.funcionario && (
              <Grid item xs={12}>
                <Button onClick={handleModal}>Enviar mensagem</Button>
              </Grid>
            )}

            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {isLoading ? (
                <div style={{ display: "flex", gap: "8px" }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="text" width={"100%"} />
                </div>
              ) : avisos.length > 0 ? (
                avisos.map((aviso) => {
                  return (
                    <div key={aviso.uuid}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {aviso.nome_remetente.substring(0, 2)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body1"
                                p={1}
                                sx={{ fontWeight: 700 }}
                              >
                                {aviso.nome_remetente}
                              </Typography>
                            </div>
                          }
                          secondary={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="body1" p={1}>
                                {aviso.descricao}
                              </Typography>

                              <Button
                                onClick={() => {
                                  removeAviso(aviso.uuid);
                                }}
                                color="error"
                              >
                                Excluir
                              </Button>
                            </div>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </div>
                  );
                })
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    color: "#212B2F",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}
                >
                  Nenhuma mensagem disponível
                </Typography>
              )}
            </List>
          </Grid>
        </Box>
      </Container>
      <Modal open={openModal} onClose={handleModal}>
        <Box sx={style}>
          <Container>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Selecione um professor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Selecione um professor"
                    value={funcSelecionado}
                    onChange={handleFuncionarioSelecionado}
                  >
                    {funcionarios.map((func) => (
                      <MenuItem value={func.uuid} key={func.uuid}>
                        {func.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  value={message}
                  onChange={handleMessageChange}
                />
                {!buttonDisabled && (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={submitMessage}
                      sx={{ mt: 2 }}
                    >
                      Enviar
                    </Button>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={handleModal} sx={{ mt: 2 }}>
                    Voltar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
