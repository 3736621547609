import { useEffect, useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  Container,
  Box,
  CssBaseline,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Modal,
  List,
  IconButton,
  ListItem,
  Divider,
  ButtonGroup,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import feedbackService from "../feedbackService";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

export default function DashboardFuncView() {
  const { getAlunos, getDoencas, postDoenca, deleteDoenca, status } =
    feedbackService();
  const [originalAlunos, setOriginalAlunos] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    nome: "",
    telefone: "",
    email: "",
  });
  const [updateFlag, setUpdateFlag] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const feedbackAnswer = async (uuid) => {
    const confirmAnswer = window.confirm(
      "Tem certeza que deseja responder o formulário do aluno(a)?"
    );

    if (confirmAnswer) {
      try {
        const response = await status(uuid);
        if (response.data.status) {
          alert("Feedback já enviado essa semana");
        } else {
          navigate(`/feedback/${uuid}`);
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAlunos();
        setOriginalAlunos(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #d9d9d9",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  /* Doenças */

  const [doencas, setDoencas] = useState([]);
  const [descricaoDoenca, setDescricaoDoenca] = useState("");

  const handleDescricaoDoencaChange = (event) => {
    setDescricaoDoenca(event.target.value);
  };

  const [openModalDoenca, setOpenModalDoenca] = useState(false);
  const [openFormDoenca, setOpenFormDoenca] = useState(false);
  const handleOpenModalDoenca = () => setOpenModalDoenca(true);
  const handleCloseModalDoenca = () => {
    setOpenModalDoenca(false);
    setOpenFormDoenca(false);
  };

  const handleDeleteDoenca = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover a doença?"
    );

    if (confirmRemoval) {
      try {
        await deleteDoenca(uuid);
        toast.success("Doença removida com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleOpenFormDoenca = () => {
    setOpenFormDoenca(true);
  };

  const handleSubmitPostDoenca = async () => {
    const form = {
      descricao: descricaoDoenca,
    };

    try {
      await postDoenca(form);
      toast.success("Doença cadastrada com sucesso!");
      setUpdateFlag((prevFlag) => !prevFlag);
      setOpenFormDoenca(false);
      setDescricaoDoenca("");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getDoencas();
        setDoencas(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  return (
    <>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 2,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 2 }}
                startIcon={<AddIcon />}
                onClick={handleOpenModalDoenca}
              >
                Doenças
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#1976D2" }}
                >
                  <Typography style={{ color: "#fff" }}>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoComplete="nome"
                            value={filter.nome}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="telefone"
                            label="Telefone"
                            name="telefone"
                            autoComplete="telefone"
                            value={filter.telefone}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            value={filter.email}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Telefone
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {originalAlunos
                      .filter((aluno) => {
                        return (
                          aluno.nome
                            .toLowerCase()
                            .includes(filter.nome.toLowerCase()) &&
                          aluno.telefone.includes(filter.telefone) &&
                          aluno.email
                            .toLowerCase()
                            .includes(filter.email.toLowerCase())
                        );
                      })
                      .map((i) => (
                        <TableRow
                          key={i.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {i.nome}
                          </TableCell>
                          <TableCell align="right">{i.telefone}</TableCell>
                          <TableCell align="right">{i.email}</TableCell>
                          <TableCell align="right">
                            <ButtonGroup variant="contained" size="large">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  feedbackAnswer(i.uuid);
                                }}
                              >
                                Responder
                              </Button>
                              <Button
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                  navigate(`/feedback/view/${i.uuid}`);
                                }}
                              >
                                Visualizar
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal open={openModalDoenca} onClose={handleCloseModalDoenca}>
        <Box sx={style}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center", mb: 2 }}
              variant="h6"
              component="div"
            >
              Doenças
            </Typography>
            <Divider />

            {openFormDoenca ? (
              <Box>
                <Grid item xs={12} md={3} sx={{ width: "100%" }}>
                  <TextField
                    required
                    fullWidth
                    id="descricao"
                    label="Doença"
                    name="descricao"
                    value={descricaoDoenca}
                    onChange={handleDescricaoDoencaChange}
                  />
                </Grid>
              </Box>
            ) : (
              <List>
                {doencas.map((doenca) => {
                  return (
                    <ListItem
                      key={doenca.uuid}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteDoenca(doenca.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      {doenca.descricao}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Grid>
          <Grid sx={{ display: "flex", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={
                openFormDoenca
                  ? handleSubmitPostDoenca
                  : () => {
                      handleOpenFormDoenca();
                    }
              }
            >
              Cadastrar
            </Button>
            <Button
              type="submit"
              variant="text"
              onClick={handleCloseModalDoenca}
            >
              Voltar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
