import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Switch,
  TextField,
  CssBaseline,
  Toolbar,
} from "@mui/material";
import InputMask from "react-input-mask";
import funcService from "../funcService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
const defaultTheme = createTheme();

export default function FormPostFunc() {
  const { postUser, postFunc } = funcService();
  const [userUuid, setUserUuid] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formatData = async () => {
      const partes = data.get("data_nascimento").split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      nome: data.get("nome"),
      email: data.get("email"),
      sexo: data.get("genero"),
      cpf: data.get("cpf").replace(/[^0-9]/g, ""),
      data_nascimento: await formatData(),
      telefone: data.get("telefone").replace(/\s/g, ""),
      ativo: data.get("ativo") ? 1 : 0,
    };

    try {
      const response = await postUser(form);
      setUserUuid(response.data.uuid);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
      } else {
        console.error(error.message);
      }
    }
  };

  useEffect(() => {
    const postFuncionario = async () => {
      try {
        await postFunc({
          uuid: "",
          user_uuid: userUuid,
          admin: "1",
        });
        toast.success("Funcionário(a) cadastrado(a) com sucesso!");
        navigate("/funcionarios");
      } catch (error) {
        toast.error(error);
      }
    };

    if (userUuid) {
      postFuncionario();
    }
  }, [userUuid]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        width: "100%",
      }}
    >
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="nome"
                  label="Nome"
                  name="nome"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid item xs={12}>
                  <FormLabel id="genero">Genero</FormLabel>
                </Grid>
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Feminino"
                  name="genero"
                />
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Masculino"
                  name="genero"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask
                        {...props}
                        mask="999.999.999-99"
                        maskChar="_"
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="data_nascimento"
                  label="Nascimento"
                  name="data_nascimento"
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99/99/9999" maskChar="_" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="telefone"
                  label="Contato"
                  name="telefone"
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99 999999999" maskChar="_" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  id="ativo"
                  required
                  control={<Switch defaultChecked />}
                  label="Ativo"
                  name="ativo"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} mt={8}>
              <Button
                sx={{ marginRight: "8px" }}
                onClick={() => {
                  navigate("/funcionarios");
                }}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Gravar
              </Button>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
