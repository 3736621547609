import { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import arquivoService from "../arquivoService";
import { useParams } from "react-router-dom";
import funcoes from "../../../composables/funcoes";

export default function Archive() {
  const { getIconByFileType } = funcoes();
  const [arquivos, setArquivos] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const { showArchives, deleteArchive } = arquivoService();
  const { uuid } = useParams();

  const handleDownload = (caminhoArquivo) => {
    const link = `https://api.jpvieirapersonal.com/storage/${caminhoArquivo}`;
    window.open(link, "_blank");
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await showArchives(uuid);
        setArquivos(response.data);
      } catch (error) {}
    }
    getData();
  }, [updateFlag]);

  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} mt={2}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mb: 3,
              "& > :not(style)": {
                m: 1,
                mr: 4,
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{ width: "100%" }}
              rowSpacing={3}
              columnSpacing={3}
            >
              {arquivos.map((arquivo) => (
                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ width: "100%" }}>
                  <div
                    key={arquivo.uuid}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#d9d9d9",
                        height: "150px",
                        borderRadius: "10px",
                        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                          marginRight: "-100px",
                          position: "relative",
                          right: "-10px",
                          bottom: "-10px",
                        }}
                      >
                        {getIconByFileType(arquivo.nome_arquivo)}
                      </div>
                    </div>

                    <div>
                      <Typography
                        variant="p"
                        style={{
                          marginBottom: 10,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "wrap",
                        }}
                      >
                        {arquivo.nome_arquivo}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            handleDownload(arquivo.caminho_arquivo)
                          }
                        >
                          Download
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
