import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };
  const postArchive = async (form) => {
    const { data } = await api.post("/portal/arquivos", form, { headers });
    return data;
  };

  const getArchive = async () => {
    const { data } = await api.get("/portal/arquivos", { headers });
    return data;
  };

  const showArchives = async (uuid) => {
    const { data } = await api.get(`/portal/arquivos?fk_uuid_aluno=${uuid}`, {
      headers,
    });
    return data;
  };

  const deleteArchive = async (uuid) => {
    const { data } = await api.delete(`/portal/arquivos/${uuid}`, {
      headers,
    });
    return data;
  };

  return {
    postArchive,
    getArchive,
    showArchives,
    deleteArchive,
  };
}
