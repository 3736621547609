import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppBar, Drawer } from "../../components/Header";
import mainListItems from "../../components/Menu";
import userInformation from "../../components/User";
import { useState, useEffect } from "react";
import WhiteLogo from "../../assets/white-logo.png";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../../src/firebase";
import {
  Container,
  Grid,
  Modal,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

const defaultTheme = createTheme();

const Layout = (PageComponent, pageName) => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [openModal, setOpenModal] = useState(false);
  const [notificationPayload, setNotificationPayload] = useState(null);

  const handleModal = () => {
    setOpenModal(false);
    setNotificationPayload(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "20px",
    width: {
      xs: "90%",
      sm: "60%",
      md: "344px",
      lg: "368px",
      xl: "416px",
    },
    padding: {
      xs: "40px 24px",
      xl: "40px",
    },
    outline: "none",
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      setNotificationPayload(payload);
      setOpenModal(true);
      console.log("Message received. ", payload);
    });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "18px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {pageName}
              </Typography>
            </div>

            <div>
              <img
                src={WhiteLogo}
                alt="Logo"
                style={{ width: "96px", ...(open && { display: "none" }) }}
              />
            </div>
            {userInformation()}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">{mainListItems()}</List>
        </Drawer>
        <Box
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            width: "100%",
          }}
        >
          <Toolbar />
          <PageComponent />
        </Box>
      </Box>
      <Modal open={openModal} onClose={handleModal}>
        <Box sx={style}>
          <Container>
            <Grid container rowSpacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <NotificationImportantIcon
                  sx={{
                    position: "absolute",
                    top: "-20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: "3rem",
                    bgcolor: "#17479D",
                    color: "#fff",
                    borderRadius: "50%",
                    padding: "8px",
                  }}
                />
                {notificationPayload && (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#212B2F",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                        lineHeight: "1.5",
                      }}
                    >
                      {notificationPayload.notification.title}
                    </Typography>
                    <Typography
                      variant="p"
                      color="text.secondary"
                      sx={{ textAlign: "center", marginTop: "16px" }}
                    >
                      {notificationPayload.notification.body}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button onClick={handleModal}>Fechar</Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Layout;
