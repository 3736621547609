import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Container, Box } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export default function Page404() {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 8,
        }}
      >
        <Typography variant="h2" component="div" gutterBottom>
          Houve um erro
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Página não encontrada
        </Typography>
        <Typography variant="p" component="div" gutterBottom textAlign="center">
          Desculpe, a página que você está procurando pode ter sido removida ou
          não existe.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          endIcon={<ArrowOutwardIcon />}
          sx={{ marginTop: 2 }}
        >
          Voltar para a Página Inicial
        </Button>
      </Box>
    </Container>
  );
}
