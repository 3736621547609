import {
  Box,
  ThemeProvider,
  Toolbar,
  Container,
  createTheme,
  Grid,
  TextField,
  FormLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Typography,
  Paper,
  Fab,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import feedbackService from "../feedbackService";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

const defaultTheme = createTheme();

export default function Formulario() {
  const { postFeedback, getDoencas, getDores, status } = feedbackService();
  const [feedbackValues, setFeedbackValues] = useState({});
  const [selectedButtonIndexSonoQuali, setSelectedButtonIndexSonoQuali] =
    useState(null);
  const [selectedButtonIndexAlimentacao, setSelectedButtonIndexAlimentacao] =
    useState(null);
  const [selectedButtonIndexDoenca, setSelectedButtonIndexDoenca] =
    useState(null);
  const [selectedButtonIndexDisposicao, setSelectedButtonIndexDisposicao] =
    useState(null);
  const [selectedButtonIndexIngestaoAgua, setSelectedButtonIndexIngestaoAgua] =
    useState(null);
  const [selectedButtonIndexOrganizacao, setSelectedButtonIndexOrganizacao] =
    useState(null);
  const [
    selectedButtonIndexIntensidadeTreino,
    setSelectedButtonIndexIntensidadeTreino,
  ] = useState(null);
  const [selectedButtonIndexAutoestima, setSelectedButtonIndexAutoestima] =
    useState(null);
  const [selectedButtonIndexTabagismo, setSelectedButtonIndexTabagismo] =
    useState(null);
  const [
    selectedButtonIndexBebidaAlcoolica,
    setSelectedButtonIndexBebidaAlcoolica,
  ] = useState(null);
  const [
    selectedButtonIndexFrequenciaAtividade,
    setSelectedButtonIndexFrequenciaAtividade,
  ] = useState(null);

  const [doencas, setDoencas] = useState([]);
  const [doencaSelecionada, setDoencaSelecionada] = useState(null);
  const handleDoencaSelcionada = (event) => {
    setDoencaSelecionada(event.target.value);
  };

  const [dorCadastrada, setDorCadastrada] = useState([]);
  const [dorSelecionada, setDorSelecionada] = useState([]);
  const handleDorSelecionada = (event) => {
    setDorSelecionada(event.target.value);
  };

  const [dorSelecionada2, setDorSelecionada2] = useState([]);
  const handleDorSelecionada2 = (event) => {
    setDorSelecionada2(event.target.value);
  };

  const [dor_obs, setDor_obs] = useState();
  const [dor_obs2, setDor_obs2] = useState();

  const [adicionarOutraDor, setAdicionarOutraDor] = useState(false);

  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButton2, setSelectedButton2] = useState(null);
  const handleAdicionarOutraDor = () => {
    setAdicionarOutraDor(true);
  };
  const handleCancelarAdicao = () => {
    setAdicionarOutraDor(false);
  };

  const handleClickBtn = (index) => {
    setSelectedButton(index);
  };

  const handleClickBtn2 = (index) => {
    setSelectedButton2(index);
  };

  const { uuid } = useParams();
  const navigate = useNavigate();

  const enviarDados = async () => {
    const values = {
      fk_uuid_aluno: uuid,
      sono_quantitativo: `${feedbackValues.sono_quantitativo}`,
      sono_quantitativo_obs: feedbackValues.sono_quantitativo_obs || "",
      sono_qualitativo: `${feedbackValues.sono_qualitativo}`,
      sono_qualitativo_obs: `${feedbackValues.sono_qualitativo_obs || ""} `,
      dores: adicionarOutraDor
        ? [
            {
              ausencia_dor: selectedButton,
              fk_uuid_dor: dorSelecionada,
              ausencia_dor_obs: dor_obs || "",
            },
            {
              ausencia_dor: selectedButton2,
              fk_uuid_dor: dorSelecionada2,
              ausencia_dor_obs: dor_obs2 || "",
            },
          ]
        : [
            {
              ausencia_dor: selectedButton,
              fk_uuid_dor: dorSelecionada,
              ausencia_dor_obs: dor_obs || "",
            },
          ],
      alimentacao: `${feedbackValues.alimentacao}`,
      alimentacao_obs: feedbackValues.alimentacao_obs || "",
      doenca: `${feedbackValues.doenca}`,
      fk_uuid_doenca: `${doencaSelecionada}`,
      doenca_obs: feedbackValues.doenca_obs || "",
      disposicao: `${feedbackValues.disposicao}`,
      disposicao_obs: feedbackValues.disposicao_obs || "",
      ingestao_agua: `${feedbackValues.ingestao_agua}`,
      ingestao_agua_obs: feedbackValues.ingestao_agua_obs || "",
      organizacao: `${feedbackValues.organizacao}`,
      organizacao_obs: feedbackValues.organizacao_obs || "",
      intensidade_treino: `${feedbackValues.intensidade_treino}`,
      intensidade_treino_obs: feedbackValues.intensidade_treino_obs || "",
      autoestima: `${feedbackValues.autoestima}`,
      autoestima_obs: feedbackValues.autoestima_obs || "",
      tabagismo: `${feedbackValues.tabagismo}`,
      tabagismo_obs: feedbackValues.tabagismo_obs || "",
      ingestao_bebida_alcoolica: `${feedbackValues.ingestao_bebida_alcoolica}`,
      ingestao_bebida_alcoolica_obs:
        feedbackValues.ingestao_bebida_alcoolica_obs || "",
      frequencia_motivacao: `${feedbackValues.frequencia_motivacao}`,
      frequencia_motivacao_obs: feedbackValues.frequencia_motivacao_obs || "",
    };

    try {
      await postFeedback(values);
      toast.success("Feedback enviado com sucesso");
      navigate("/");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }

    setFeedbackValues({});
  };

  useEffect(() => {
    async function getData() {
      try {
        const responseStatus = await status(uuid);
        if (responseStatus.data.status) {
          toast.warn("Feedback já enviado essa semana");
          navigate("/");
        } else {
          const responseDoencas = await getDoencas();
          setDoencas(responseDoencas.data);
          const responseDores = await getDores();
          setDorCadastrada(responseDores.data);
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
    getData();
  }, []);

  function FeedbackButtons({
    question,
    options,
    onFeedbackChange,
    selectedButtonIndex,
    setSelectedButtonIndex,
  }) {
    const handleButtonClick = (index) => {
      setSelectedButtonIndex(index);
      onFeedbackChange(index);
    };

    const getColor = (index) => {
      const color =
        index === 0 || index === 1
          ? "error"
          : index === 2 || index === 3
          ? "warning"
          : "success";
      return color;
    };

    const getVariant = (index, selectedIndex) => {
      const variant = selectedIndex === index ? "contained" : "outlined";
      return variant;
    };

    return (
      <>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            mt: 3,
          }}
        >
          <FormLabel sx={{ padding: "0 10px" }}>
            <Typography>{question}</Typography>
          </FormLabel>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <ButtonGroup sx={{ width: "95%" }} orientation="vertical">
            {options.map((option, index) => (
              <Button
                key={index}
                color={getColor(index)}
                variant={getVariant(index, selectedButtonIndex)}
                onClick={() => handleButtonClick(index)}
              >
                {option}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                  }}
                >
                  <FeedbackButtons
                    question="Como está sua qualidade de sono?"
                    options={[
                      "Péssimo",
                      "Ruim",
                      "Média",
                      "Bom",
                      "Muito bom",
                      "Excelente",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        sono_qualitativo: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexSonoQuali}
                    setSelectedButtonIndex={setSelectedButtonIndexSonoQuali}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          sono_qualitativo_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <FormLabel>
                      Quantidade de horas dormidas por dia:{" "}
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl sx={{ width: "95%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Horas
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={feedbackValues.sono_quantitativo}
                        label="Horas"
                        onChange={(event) => {
                          const value = event.target.value;
                          setFeedbackValues({
                            ...feedbackValues,
                            sono_quantitativo: value,
                          });
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          sono_quantitativo_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      mt: 3,
                    }}
                  >
                    <FormLabel>
                      <Typography>
                        Dê uma nota para a Ausência de dor (classifique de
                        acordo com o grau da sua dor, recorrência na sua semana
                        e o quanto ela limita suas tarefas diárias).
                      </Typography>
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <ButtonGroup sx={{ width: "95%" }} orientation="vertical">
                      <Button
                        variant={
                          selectedButton === 0 ? "contained" : "outlined"
                        }
                        color="error"
                        onClick={() => handleClickBtn(0)}
                      >
                        Dores fortes, limitantes, sempre
                      </Button>
                      <Button
                        variant={
                          selectedButton === 1 ? "contained" : "outlined"
                        }
                        color="error"
                        onClick={() => handleClickBtn(1)}
                      >
                        Dores fortes, limitantes, alguns dias
                      </Button>
                      <Button
                        variant={
                          selectedButton === 2 ? "contained" : "outlined"
                        }
                        color="warning"
                        onClick={() => handleClickBtn(2)}
                      >
                        Dores leves, limitantes, sempre
                      </Button>
                      <Button
                        variant={
                          selectedButton === 3 ? "contained" : "outlined"
                        }
                        color="warning"
                        onClick={() => handleClickBtn(3)}
                      >
                        Dores leves, não limitantes, sempre
                      </Button>
                      <Button
                        variant={
                          selectedButton === 4 ? "contained" : "outlined"
                        }
                        color="success"
                        onClick={() => handleClickBtn(4)}
                      >
                        Dores leves, não limitantes, alguns dias
                      </Button>
                      <Button
                        variant={
                          selectedButton === 5 ? "contained" : "outlined"
                        }
                        color="success"
                        onClick={() => handleClickBtn(5)}
                      >
                        Sem dores
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      mt: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ width: "95%" }}>
                      <InputLabel id="demo-simple-select-label-dores">
                        Selecione o local da dor
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-dores"
                        id="demo-simple-select-dores"
                        label="Selecione o local da dor"
                        onChange={handleDorSelecionada}
                        value={dorSelecionada || ""}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        {dorCadastrada.map((dor) => (
                          <MenuItem value={dor.uuid} key={dor.uuid}>
                            {dor.descricao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setDor_obs(value);
                      }}
                    />
                    {!adicionarOutraDor && (
                      <>
                        Adicionar outra dor
                        <Fab
                          color="primary"
                          onClick={handleAdicionarOutraDor}
                          sx={{ mb: 3 }}
                        >
                          <AddIcon />
                        </Fab>
                      </>
                    )}
                  </Grid>
                  {adicionarOutraDor && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          mt: 3,
                        }}
                      >
                        <FormLabel>
                          <Typography>
                            Dê uma nota para a Ausência de dor (classifique de
                            acordo com o grau da sua dor, recorrência na sua
                            semana e o quanto ela limita suas tarefas diárias).
                          </Typography>
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 3,
                        }}
                      >
                        <ButtonGroup
                          sx={{ width: "95%" }}
                          orientation="vertical"
                        >
                          <Button
                            variant={
                              selectedButton2 === 0 ? "contained" : "outlined"
                            }
                            color="error"
                            onClick={() => handleClickBtn2(0)}
                          >
                            Dores fortes, limitantes, sempre
                          </Button>
                          <Button
                            variant={
                              selectedButton2 === 1 ? "contained" : "outlined"
                            }
                            color="error"
                            onClick={() => handleClickBtn2(1)}
                          >
                            Dores fortes, limitantes, alguns dias
                          </Button>
                          <Button
                            variant={
                              selectedButton2 === 2 ? "contained" : "outlined"
                            }
                            color="warning"
                            onClick={() => handleClickBtn2(2)}
                          >
                            Dores leves, limitantes, sempre
                          </Button>
                          <Button
                            variant={
                              selectedButton2 === 3 ? "contained" : "outlined"
                            }
                            color="warning"
                            onClick={() => handleClickBtn2(3)}
                          >
                            Dores leves, não limitantes, sempre
                          </Button>
                          <Button
                            variant={
                              selectedButton2 === 4 ? "contained" : "outlined"
                            }
                            color="success"
                            onClick={() => handleClickBtn2(4)}
                          >
                            Dores leves, não limitantes, alguns dias
                          </Button>
                          <Button
                            variant={
                              selectedButton2 === 5 ? "contained" : "outlined"
                            }
                            color="success"
                            onClick={() => handleClickBtn2(5)}
                          >
                            Sem dores
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          width: "100%",
                          mt: 3,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <FormControl sx={{ width: "95%" }}>
                          <InputLabel id="demo-simple-select-label-dores">
                            Selecione o local da dor
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label-dores"
                            id="demo-simple-select-dores"
                            label="Selecione o local da dor"
                            onChange={handleDorSelecionada2}
                            value={dorSelecionada2 || ""}
                          >
                            <MenuItem value="">Selecione</MenuItem>
                            {dorCadastrada.map((dor) => (
                              <MenuItem value={dor.uuid} key={dor.uuid}>
                                {dor.descricao}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          mt: 1,
                        }}
                      >
                        <TextField
                          multiline
                          maxRows={4}
                          placeholder="Observação"
                          sx={{ width: "95%", mb: 3 }}
                          onChange={(event) => {
                            const value = event.target.value;
                            setDor_obs2(value);
                          }}
                        />
                        {adicionarOutraDor && (
                          <Button onClick={handleCancelarAdicao} sx={{ mb: 3 }}>
                            Remover formulário extra
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Alimentação, dê uma nota de 5 para sua alimentação. (Assista o vídeo na área educacional para avaliar melhor sua nota)"
                    options={[
                      "Péssima",
                      "Ruim",
                      "Média",
                      "Boa",
                      "Muito boa",
                      "Excelente",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        alimentacao: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexAlimentacao}
                    setSelectedButtonIndex={setSelectedButtonIndexAlimentacao}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          alimentacao_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Você está saudável? (Sem acometimento de sintomas de nenhuma doença e se sentindo bem)"
                    options={[
                      "Doente com muitos sintomas, sem medicação",
                      "Doente com sintomas, com medicação",
                      "Doente, sem sintomas, com medicação",
                      "Recuperado, sem medicação",
                      "Saudável, sem hábitos preventivos",
                      "Saudável, com hábitos preventivos",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        doenca: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexDoenca}
                    setSelectedButtonIndex={setSelectedButtonIndexDoenca}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      mt: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ width: "95%" }}>
                      <InputLabel id="demo-simple-select-label-doenca">
                        Selecione o tipo da doença
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-doenca"
                        id="demo-simple-select-doenca"
                        label="Selecione o tipo da doenca"
                        onChange={handleDoencaSelcionada}
                        value={doencaSelecionada || ""}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        {doencas.map((doenca) => (
                          <MenuItem value={doenca.uuid} key={doenca.uuid}>
                            {doenca.descricao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          doenca_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Como está seu nível de disposição no dia-dia?
                    "
                    options={[
                      "Vontade de dormir o dia todo",
                      "Preguiçoso",
                      "Leve indisposição para as tarefas diárias",
                      "Faço o necessário",
                      "Bem disposto",
                      "Muito disposto e proativo",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        disposicao: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexDisposicao}
                    setSelectedButtonIndex={setSelectedButtonIndexDisposicao}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          disposicao_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Classifique sua ingestão de água (Qntd adequada de água: 40ml x Peso corporal)"
                    options={[
                      "Péssima",
                      "Ruim",
                      "Média",
                      "Boa",
                      "Próxima do ideal",
                      "Ideal",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        ingestao_agua: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexIngestaoAgua}
                    setSelectedButtonIndex={setSelectedButtonIndexIngestaoAgua}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          ingestao_agua_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Organização (Classifique de 0 a 5 seu nível de organização no trabalho e hábitos saudáveis diários)"
                    options={[
                      "Extremamente desorganizado",
                      "Desorganizado em ambos",
                      "Desorganizado com meus hábitos de saúde",
                      "Organizado, mas sem plano de ação",
                      "Organizado, com plano de ação",
                      "Organizado, com plano de ação e agindo conforme a determinação de suas metas",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        organizacao: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexOrganizacao}
                    setSelectedButtonIndex={setSelectedButtonIndexOrganizacao}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          organizacao_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Classifique de 0 a 5 a intensidade dos seus treinos
                    "
                    options={[
                      "Muito leve",
                      "Leve",
                      "Moderado",
                      "Um pouco intenso",
                      "Intenso",
                      "Muito intenso",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        intensidade_treino: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexIntensidadeTreino}
                    setSelectedButtonIndex={
                      setSelectedButtonIndexIntensidadeTreino
                    }
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          intensidade_treino_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Classifique de 0 a 5  sua autoestima
                    "
                    options={[
                      "Péssima",
                      "Ruim",
                      "As vezes me sinto mal",
                      "Insatisfeito",
                      "Satisfeito",
                      "Me sinto excelente",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        autoestima: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexAutoestima}
                    setSelectedButtonIndex={setSelectedButtonIndexAutoestima}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          autoestima_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Tabagismo
                    "
                    options={[
                      "Fumante em grandes quantidades, todos os dias",
                      "Fumante pouca quantidade, todos os dias",
                      "Fumante, alguns dias da semana",
                      "Fumante, muito eventualmente",
                      "Fumante passivo",
                      "Não fumante",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        tabagismo: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexTabagismo}
                    setSelectedButtonIndex={setSelectedButtonIndexTabagismo}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          tabagismo_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Ingestão alcoolica"
                    options={[
                      "Péssimo, todos os dias",
                      "Muito ruim, recorrência: 6 dias na semana",
                      "Ruim, recorrência: 2 dias na semana",
                      "Ok, recorrência: 1 dia na semana",
                      "Não bebi nesta semana, mas não interrompi a ingestão de alcool",
                      "Abstêmio (não bebe)",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        ingestao_bebida_alcoolica: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexBebidaAlcoolica}
                    setSelectedButtonIndex={
                      setSelectedButtonIndexBebidaAlcoolica
                    }
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          ingestao_bebida_alcoolica_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "16px",
                    mt: 3,
                  }}
                >
                  <FeedbackButtons
                    question="Como anda a sua frequência e motivação para os treinos e atividades físicas?"
                    options={[
                      "Desmotivado, sem treinar",
                      "Desmotivado, fiz um ou outro exercício",
                      "Sem treinar, apenas fazendo atividades físicas",
                      "Treinando, mas sem motivação",
                      "Treinando e motivado",
                      "Treinando muito bem, motivado e fazendo atividades físicas extras",
                    ]}
                    onFeedbackChange={(value) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        frequencia_motivacao: value,
                      });
                    }}
                    selectedButtonIndex={selectedButtonIndexFrequenciaAtividade}
                    setSelectedButtonIndex={
                      setSelectedButtonIndexFrequenciaAtividade
                    }
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={4}
                      placeholder="Observação"
                      sx={{ width: "95%", mb: 3 }}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFeedbackValues({
                          ...feedbackValues,
                          frequencia_motivacao_obs: value,
                        });
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Button variant="contained" onClick={enviarDados}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
