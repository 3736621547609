import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import calendarioService from "../calendarioService";
import statusService from "../statusService";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  Typography,
  Divider,
  TextField,
  List,
  ListItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Stack,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ModalStatus from "../ModalAdmin/status";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";

const defaultTheme = createTheme();

export default function DashboardFuncView() {
  const {
    getAlunos,
    getCalendario,
    getAtvFisica,
    postAtvFisica,
    deleteAtvFisica,
    getContador,
    postEvento,
    putEvento,
    deleteEvento,
    showCalendario,
  } = calendarioService();

  const [alunos, setAlunos] = useState([]);
  const [alunoSelecionado, setAlunoSelecionado] = useState(null);
  const [contador, setContador] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [eventos, setEventos] = useState([]);
  const [mesAtual, setMesAtual] = useState(new Date());
  const [uuidEvento, setUuidEvento] = useState();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const handleOpenModalStatus = () => {
    setOpenModalStatus(true);
  };
  const handleCloseModalStatus = () => {
    setOpenModalStatus(false);
  };

  //Atividade fisica
  const [atvFisica, setAtvFisica] = useState([]);

  const [descricaoAtv, setDescricaoAtv] = useState("");
  const handleDescricaoAtvChange = (event) => {
    setDescricaoAtv(event.target.value);
  };
  const [openModalAtvFisica, setOpenModalAtvFisica] = useState(false);
  const [openFormAtv, setOpenFormAtv] = useState(false);
  const handleOpenModalAtvFisica = () => setOpenModalAtvFisica(true);
  const handleCloseModalAtvFisica = () => {
    setOpenModalAtvFisica(false);
    setOpenFormAtv(false);
  };

  const handleDeleteAtvFisica = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover a atividade?"
    );

    if (confirmRemoval) {
      try {
        await deleteAtvFisica(uuid);
        toast.success("Atividade removida com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(
            "Desvincule o(s) eventos(s) dessa atividade para excluí-la"
          );
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleOpenFormAtvFisica = () => {
    setOpenFormAtv(true);
  };

  const handleSubmitPostAtvFisica = async () => {
    const form = {
      descricao: descricaoAtv,
    };

    try {
      await postAtvFisica(form);
      toast.success("Atividade cadastrada com sucesso!");
      setUpdateFlag((prevFlag) => !prevFlag);
      setOpenFormAtv(false);
      setDescricaoAtv("");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAtvFisica();
        setAtvFisica(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAlunos();
        setAlunos(response.data);
      } catch (error) {
        console.error("Erro ao buscar os alunos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (alunoSelecionado) {
        try {
          const response = await getContador(alunoSelecionado.uuid, mesAtual);
          setContador(response.data);
        } catch (error) {
          console.error("Erro ao buscar os alunos:", error);
        }
      }
    };

    fetchData();
  }, [alunoSelecionado]);

  const handleAlunoSelecionado = (event) => {
    const uuid = event.target.value;
    const aluno = alunos.find((aluno) => aluno.uuid === uuid);
    setAlunoSelecionado(aluno);
    setTodos(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  // evento
  const [openModalEvent, setOpenModalEvent] = useState(false);
  const { getStatus } = statusService();
  const [status, setStatus] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState(null);
  const [recorrencia, setRecorrencia] = useState(false);
  const [todos, setTodos] = useState(true);
  const [editingCalendario, setEditingCalendario] = useState(false);
  const [atividadeFisicaSelecionada, setAtividadeFisicaSelecionada] =
    useState("");
  const [formValuesEvent, setFormValuesEvent] = useState({
    uuid: "",
    data: "",
    data_inicio: "",
    data_fim: "",
    dias_semana: [],
    titulo: "",
    fk_uuid_aluno: "",
    fk_uuid_status: "",
    fk_uuid_atividade_fisica: "",
    status: "",
    cor: "",
  });

  const handleOpenModalEvent = () => {
    setOpenModalEvent(true);
  };

  const handleCloseModalEvent = () => {
    setUuidEvento();
    setEditingCalendario(false);
    setOpenModalEvent(false);
    setAtividadeFisicaSelecionada("");
    setRecorrencia(false);
    setStatusSelecionado(null);
  };

  const handleAtividadeFisicaSelecionada = (event) => {
    setAtividadeFisicaSelecionada(event.target.value);
  };
  const handleCheckboxRecorrenciaChange = (event) => {
    setRecorrencia(event.target.checked);
  };
  const handleDataInicioChange = (date) => {
    setFormValuesEvent((prevFormValues) => ({
      ...prevFormValues,
      data_inicio: date.format("YYYY-MM-DD"), // Armazena a data de início no formato 'YYYY-MM-DD'
    }));
  };
  const handleDataTerminoChange = (date) => {
    setFormValuesEvent((prevFormValues) => ({
      ...prevFormValues,
      data_fim: date.format("YYYY-MM-DD"), // Armazena a data de término no formato 'YYYY-MM-DD'
    }));
  };
  const handleRecorrenciaChange = (event) => {
    const selectedDays = event.target.value; // Obtém os dias da semana selecionados
    setFormValuesEvent((prevFormValues) => ({
      ...prevFormValues,
      dias_semana: selectedDays, // Armazena os dias da semana selecionados
    }));
  };
  const handleTituloChange = (event) => {
    setFormValuesEvent((prevFormValues) => ({
      ...prevFormValues,
      titulo: event.target.value,
    }));
  };
  const handleDateChange = (date) => {
    const dateObject = date.toDate();
    setFormValuesEvent((prevFormValues) => ({
      ...prevFormValues,
      data: dateObject,
    }));
  };
  const handleCheckboxChange = (event) => {
    setTodos(event.target.checked);
  };
  const handleStatusSelcionado = (event) => {
    setStatusSelecionado(event.target.value);
  };

  const handleSubmit = async () => {
    const form = {
      data:
        !recorrencia && formValuesEvent.data
          ? formValuesEvent.data.toISOString().split("T")[0]
          : "",
      titulo: formValuesEvent.titulo,
      fk_uuid_aluno: alunoSelecionado ? alunoSelecionado : "",
      fk_uuid_status: statusSelecionado,
      fk_uuid_atividade_fisica:
        atividadeFisicaSelecionada !== null ? atividadeFisicaSelecionada : "",
      todos: todos,
      data_inicio: formValuesEvent.data_inicio,
      data_fim: formValuesEvent.data_fim,
      dias_semana: formValuesEvent.dias_semana,
      evento_aluno: false,
    };

    try {
      await postEvento(form);
      setUpdateFlag((prevFlag) => !prevFlag);
      toast.success("Evento criado com sucesso!");
      handleCloseModalEvent();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleEdit = async () => {
    const data =
      typeof formValuesEvent.data === "string"
        ? new Date(formValuesEvent.data)
        : formValuesEvent.data;
    const dataFormatada = data.toISOString().split("T")[0];

    const form = {
      uuid: uuidEvento,
      data: !recorrencia ? dataFormatada : "",
      titulo: formValuesEvent.titulo,
      fk_uuid_aluno: alunoSelecionado ? alunoSelecionado : "",
      fk_uuid_status: statusSelecionado !== null ? statusSelecionado : "",
      fk_uuid_atividade_fisica:
        atividadeFisicaSelecionada !== null ? atividadeFisicaSelecionada : "",
      todos: todos,
      evento_aluno: false,
      data_inicio: formValuesEvent.data_inicio,
      data_fim: formValuesEvent.data_fim,
      dias_semana: formValuesEvent.dias_semana,
    };

    try {
      await putEvento(uuidEvento, form);
      setUpdateFlag((prevFlag) => !prevFlag);
      toast.success("Informações editadas com sucesso!");
      handleCloseModalEvent();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleDelete = async (uuidEvent) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o evento?"
    );

    if (confirmRemoval) {
      try {
        await deleteEvento(uuidEvent);
        setUpdateFlag((prevFlag) => !prevFlag);
        toast.success("Evento removido com sucesso!");
        setEditingCalendario(false);
        handleCloseModalEvent();
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStatus = await getStatus();
        setStatus(responseStatus.data);
      } catch (error) {
        console.error("Erro ao buscar os status:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let alunoId = alunoSelecionado ? alunoSelecionado.uuid : null;
        const response = await getCalendario(
          alunoSelecionado == null && true,
          alunoId,
          mesAtual
        );
        setEventos(response);
        setIsLoading(false);
      } catch (error) {
        toast.error("Erro ao buscar o calendario:", error);
      }
    };

    fetchData();
  }, [alunoSelecionado, mesAtual, updateFlag]);

  useEffect(() => {
    if (openModalEvent && uuidEvento) {
      const fetchData = async () => {
        try {
          const response = await showCalendario(uuidEvento);
          setEditingCalendario(true);
          const {
            cor,
            data,
            titulo,
            fk_uuid_aluno,
            fk_uuid_status,
            fk_uuid_atividade_fisica,
            status,
            todos,
            uuid,
            data_inicio,
            data_fim,
            dias_semana,
          } = response;
          setFormValuesEvent({
            ...formValuesEvent,
            cor,
            data,
            titulo,
            fk_uuid_aluno,
            fk_uuid_status,
            fk_uuid_atividade_fisica,
            status,
            uuid,
            data_inicio,
            data_fim,
            dias_semana,
          });
          setTodos(todos);
          if (fk_uuid_status) {
            setStatusSelecionado(fk_uuid_status);
          }
          if (fk_uuid_atividade_fisica) {
            setAtividadeFisicaSelecionada(fk_uuid_atividade_fisica);
          }
        } catch (error) {
          toast.error("Erro ao buscar os detalhes do evento:", error);
        }
      };
      fetchData();
    } else {
      setFormValuesEvent({
        uuid: "",
        data: "",
        titulo: "",
        fk_uuid_aluno: "",
        fk_uuid_status: "",
        status: "",
        cor: "",
        data_inicio: "",
        data_fim: "",
        dias_semana: [],
      });
      setEditingCalendario(false);
      setTodos(true);
    }
  }, [openModalEvent, uuidEvento]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Button variant="contained" onClick={handleOpenModalEvent}>
                Criar evento
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  handleOpenModalStatus();
                }}
                sx={{ ml: 1 }}
              >
                Criar Status
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleOpenModalAtvFisica}
                sx={{ ml: 1 }}
              >
                Atividades Fisicas
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selecione um aluno
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Selecione um aluno"
                  onChange={handleAlunoSelecionado}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {alunos.map((aluno) => (
                    <MenuItem value={aluno.uuid} key={aluno.uuid}>
                      {aluno.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {alunoSelecionado && (
              <Grid item xs={12} sx={{ display: "flex", gap: "8px" }}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "16px",
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#2e7d32",
                    opacity: "0.7",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "1.125rem",
                      lineHeight: "1.5",
                    }}
                  >
                    Presenças: {contador?.presenca}
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    borderRadius: "16px",
                    width: "100%",
                    backgroundColor: "#d32f2f",
                    opacity: "0.7",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#fff",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                        lineHeight: "1.5",
                      }}
                    >
                      Faltas: {contador?.falta}
                    </Typography>
                  </Stack>
                </Paper>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    borderRadius: "16px",
                    width: "100%",
                    backgroundColor: "#1976d2",
                    opacity: "0.7",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#fff",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                        lineHeight: "1.5",
                      }}
                    >
                      Treinos: {contador?.treino}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Calendar
                eventos={eventos}
                setMesAtual={setMesAtual}
                handleEventClicked={(uuid) => {
                  setUuidEvento(uuid);
                  setEditingCalendario(true);
                  handleOpenModalEvent();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Modal open={openModalEvent} onClose={handleCloseModalEvent}>
        <Box sx={style}>
          <Container>
            <Grid container>
              {!editingCalendario && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recorrencia}
                        onChange={handleCheckboxRecorrenciaChange}
                        color="primary"
                      />
                    }
                    label="Recorrência"
                  />
                </Grid>
              )}

              {recorrencia ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="Data de Início"
                          value={
                            formValuesEvent.data_inicio
                              ? dayjs(formValuesEvent.data_inicio)
                              : null
                          }
                          onChange={handleDataInicioChange}
                          sx={{ width: "100%" }}
                        />

                        <DatePicker
                          label="Data de Término"
                          value={
                            formValuesEvent.data_fim
                              ? dayjs(formValuesEvent.data_fim)
                              : null
                          }
                          onChange={handleDataTerminoChange}
                          sx={{ width: "100%" }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      mt: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-recorrencia">
                        Selecione os dias da semana
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-recorrencia"
                        id="demo-simple-select-recorrencia"
                        label="Selecione os dias da semana"
                        onChange={handleRecorrenciaChange}
                        value={formValuesEvent.dias_semana || []}
                        multiple
                      >
                        <MenuItem value={0}>Domingo</MenuItem>
                        <MenuItem value={1}>Segunda-feira</MenuItem>
                        <MenuItem value={2}>Terça-feira</MenuItem>
                        <MenuItem value={3}>Quarta-feira</MenuItem>
                        <MenuItem value={4}>Quinta-feira</MenuItem>
                        <MenuItem value={5}>Sexta-feira</MenuItem>
                        <MenuItem value={6}>Sábado</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <DatePicker
                        label="Selecione a data"
                        value={
                          formValuesEvent.data
                            ? dayjs(formValuesEvent.data)
                            : null
                        }
                        onChange={handleDateChange}
                        sx={{ width: "100%" }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  value={formValuesEvent.titulo}
                  onChange={handleTituloChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-atividade">
                    Selecione a atividade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-atividade"
                    id="demo-simple-select-atividade"
                    label="Selecione a atividade"
                    onChange={handleAtividadeFisicaSelecionada}
                    value={atividadeFisicaSelecionada || ""}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {atvFisica.map((atv) => (
                      <MenuItem value={atv.uuid} key={atv.uuid}>
                        {atv.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!todos && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Selecione um aluno
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Selecione um aluno"
                      value={alunoSelecionado ? alunoSelecionado.uuid : ""}
                      onChange={handleAlunoSelecionado}
                    >
                      {alunoSelecionado ? (
                        <MenuItem value={alunoSelecionado.uuid}>
                          {alunoSelecionado.nome}
                        </MenuItem>
                      ) : (
                        [
                          <MenuItem value="" key="placeholder">
                            Selecione
                          </MenuItem>,
                          alunos.map((aluno) => (
                            <MenuItem value={aluno.uuid} key={aluno.uuid}>
                              {aluno.nome}
                            </MenuItem>
                          )),
                        ]
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-status">
                    Selecione um status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-status"
                    id="demo-simple-select-status"
                    label="Selecione um status"
                    onChange={handleStatusSelcionado}
                    value={statusSelecionado || ""}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {status.map((s) => (
                      <MenuItem value={s.uuid} key={s.uuid}>
                        {s.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!alunoSelecionado && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={todos}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Todos"
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  pt: 1,
                }}
              >
                {editingCalendario ? (
                  <Button variant="contained" onClick={handleEdit}>
                    Editar
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleSubmit}>
                    Gravar
                  </Button>
                )}
                {editingCalendario && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleDelete(uuidEvento);
                    }}
                  >
                    Excluir
                  </Button>
                )}
                <Button onClick={handleCloseModalEvent}>Voltar</Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>

      <ModalStatus
        openModal={openModalStatus}
        handleClose={handleCloseModalStatus}
      />
      <Modal open={openModalAtvFisica} onClose={handleCloseModalAtvFisica}>
        <Box sx={style}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center", mb: 2 }}
              variant="h6"
              component="div"
            >
              Atividades Fisicas
            </Typography>
            <Divider />
            {openFormAtv ? (
              <Box>
                <Grid item xs={12} md={3} sx={{ width: "100%" }}>
                  <TextField
                    required
                    fullWidth
                    id="atv_fisica"
                    label="Atividade fisica"
                    name="atv_fisica"
                    value={descricaoAtv}
                    onChange={handleDescricaoAtvChange}
                  />
                </Grid>
              </Box>
            ) : (
              <List>
                {atvFisica.map((atv) => {
                  return (
                    <ListItem
                      key={atv.uuid}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteAtvFisica(atv.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      {atv.descricao}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Grid>
          <Grid sx={{ display: "flex", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={
                openFormAtv
                  ? handleSubmitPostAtvFisica
                  : () => {
                      handleOpenFormAtvFisica();
                    }
              }
            >
              Cadastrar
            </Button>
            <Button
              type="submit"
              variant="text"
              onClick={handleCloseModalAtvFisica}
            >
              Voltar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

function Calendar({ eventos, setMesAtual, handleEventClicked }) {
  const handleEventClick = (clickInfo) => {
    handleEventClicked(clickInfo.event._def.extendedProps.uuid);
  };

  const handleDatesSet = (arg) => {
    const date = arg.view.currentStart;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;
    setMesAtual(formattedDate);
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      locale="pt-br"
      height="auto"
      headerToolbar={{
        start: "",
        center: "title",
        end: "prev,next",
      }}
      events={eventos}
      eventClick={handleEventClick}
      datesSet={(arg) => handleDatesSet(arg)}
    />
  );
}
