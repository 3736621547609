import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getAnamnese = async (uuid) => {
    const { data } = await api.get(
      `/portal/ficha-anamnese?fk_uuid_aluno=${uuid}`,
      { headers }
    );
    return data;
  };

  const postAnamnese = async (form) => {
    const { data } = await api.post("/portal/ficha-anamnese", form, {
      headers,
    });
    return data;
  };

  const editAnamnese = async (uuid, form) => {
    const { data } = await api.put(`/portal/ficha-anamnese/${uuid}`, form, {
      headers,
    });
    return data;
  };

  const showAnamnese = async (uuid) => {
    const { data } = await api.get(`/portal/ficha-anamnese/${uuid}`, {
      headers,
    });
    return data;
  };

  const deleteAnamnese = async (uuid) => {
    const { data } = await api.delete(`/portal/ficha-anamnese/${uuid}`, {
      headers,
    });
    return data;
  };

  return {
    postAnamnese,
    getAnamnese,
    showAnamnese,
    editAnamnese,
    deleteAnamnese,
  };
}
