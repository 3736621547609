import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Tooltip,
  LinearProgress,
  Button,
  CssBaseline,
  Box,
  Container,
  Grid,
  Paper,
  Skeleton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import avisosService from "../../Avisos/avisosService";
import feedbackService from "../../Feedback/feedbackService";
import { useNavigate } from "react-router-dom";

export default function DashboardUserView({ uuid }) {
  const [isLoading, setIsLoading] = useState(true);
  const [avisos, setAvisos] = useState([]);
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [score, setScore] = useState();
  const { getAvisos } = avisosService();
  const { status, scoreSemanal } = feedbackService();
  const navigate = useNavigate();

  const getColor = (progress) => {
    if (progress <= 24) {
      return "error";
    } else if (progress <= 48) {
      return "warning";
    } else {
      return "success";
    }
  };

  const getPhrase = (progress) => {
    if (progress <= 24) {
      return "Saúde em risco";
    } else if (progress <= 48) {
      return "Você ainda pode melhorar";
    } else {
      return "Você está bem, continue assim";
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAvisos(uuid);
        setAvisos(response.data);
        const statusFeedback = await status(uuid);
        setFeedbackStatus(statusFeedback.data.status);
        const score = await scoreSemanal(uuid);
        setScore(score.data.score);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          mb: 3,
        }}
      >
        <Grid container spacing={3} sx={{ display: "flex" }}>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#212B2F",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                    lineHeight: "1.5",
                  }}
                >
                  Seu score semanal
                </Typography>
                <Tooltip
                  title="Resultado com base na resposta do Feedback Semanal"
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "4px",
                    marginBottom: "3px",
                  }}
                >
                  <InfoIcon fontSize="small" color="action" />
                </Tooltip>
              </div>
              {isLoading ? (
                <>
                  <Skeleton variant="text" width={50} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" width={200} />
                </>
              ) : (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: getColor(score),
                        fontWeight: "700",
                        fontSize: "2rem",
                        lineHeight: "1.5",
                      }}
                    >
                      {score}
                    </Typography>
                    <Typography variant="overline">pts</Typography>
                  </div>

                  <LinearProgress
                    value={(score / 72) * 100}
                    variant="determinate"
                    color={getColor(score)}
                  />
                  <Typography color="text.secondary" variant="p">
                    {getPhrase(score)}
                  </Typography>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "16px",
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#212B2F",
                      fontWeight: "700",
                      fontSize: "1.125rem",
                      lineHeight: "1.5",
                    }}
                  >
                    Feedback semanal 🤭
                  </Typography>

                  {isLoading ? (
                    <Skeleton variant="text" width={200} />
                  ) : feedbackStatus ? (
                    <Typography variant="p" color="text.secondary">
                      Voce ja respondeu o feedback dessa semana
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="p" color="text.secondary">
                        Responda o feedback semanal
                      </Typography>
                      <div>
                        <Button
                          onClick={() => {
                            navigate(`/feedback/${uuid}`);
                          }}
                        >
                          Responder
                        </Button>
                      </div>
                    </>
                  )}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "16px",
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#212B2F",
                      fontWeight: "700",
                      fontSize: "1.125rem",
                      lineHeight: "1.5",
                    }}
                  >
                    Mensagens 💬
                  </Typography>

                  {isLoading ? (
                    <Skeleton variant="text" width={200} />
                  ) : avisos !== null ? (
                    <>
                      <Typography color="text.secondary" variant="p">
                        Voce tem mensagens não lidas
                      </Typography>
                      <div>
                        <Button
                          onClick={() => {
                            navigate(`/avisos/${uuid}`);
                          }}
                        >
                          Ler mensagens
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Typography color="text.secondary" variant="p">
                      Voce não tem mensagens
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "16px",
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#212B2F",
                      fontWeight: "700",
                      fontSize: "1.125rem",
                      lineHeight: "1.5",
                    }}
                  >
                    Calendário 📅
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    Fez alguma atividade ou exercicio fisico? Marque no
                    calendário para o JP saber
                  </Typography>
                  <div>
                    <Button
                      onClick={() => {
                        navigate("/calendario");
                      }}
                    >
                      Ir ao calendario
                    </Button>
                  </div>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
