import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getAlunos = async () => {
    const { data } = await api.get("/portal/aluno", { headers });
    return data;
  };

  const getFeedbacks = async (uuid) => {
    const { data } = await api.get(
      `portal/feedback-semanal?fk_uuid_aluno=${uuid}`,
      {
        headers,
      }
    );
    return data;
  };

  const showFeedback = async (uuid) => {
    const { data } = await api.get(`portal/feedback-semanal/${uuid}`, {
      headers,
    });
    return data;
  };

  const postFeedback = async (form) => {
    const { data } = await api.post("portal/feedback-semanal", form, {
      headers,
    });
    return data;
  };

  const getDores = async () => {
    const { data } = await api.get("portal/dores", {
      headers,
    });
    return data;
  };

  const getDoencas = async () => {
    const { data } = await api.get("portal/doencas", {
      headers,
    });
    return data;
  };

  const postDoenca = async (form) => {
    const { data } = await api.post("portal/doencas", form, {
      headers,
    });
    return data;
  };

  const deleteDoenca = async (uuid) => {
    const { data } = await api.delete(`portal/doencas/${uuid}`, {
      headers,
    });
    return data;
  };

  const status = async (uuid) => {
    const { data } = await api.get(`/portal/feedback-semanal/status/${uuid}`, {
      headers,
    });
    return data;
  };

  const scoreSemanal = async (uuid) => {
    const { data } = await api.get(
      `/portal/feedback-semanal/score-semanal/${uuid}`,
      {
        headers,
      }
    );
    return data;
  };

  return {
    getAlunos,
    getFeedbacks,
    showFeedback,
    postFeedback,
    getDores,
    getDoencas,
    postDoenca,
    deleteDoenca,
    status,
    scoreSemanal,
  };
}
