import { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  TextField,
  Box,
  CssBaseline,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import anamneseService from "../anamneseService";
import { format } from "date-fns";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function FormEditUser() {
  const navigate = useNavigate();
  const { showAnamnese, editAnamnese } = anamneseService();
  const { useruuid, uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [anamnese, setAnamnese] = useState({
    data_realizacao_ultimo_backup: "",
    data_realizacao_ultimo_backup_obs: "",
    suplementacao: "",
    patologias: "",
    peso: "",
    idade: "",
    limitacoes: "",
    limitacoes_obs: "",
    setor_atuacao: "",
    objetivos_iniciais: "",
    comprometimento_resultado: "",
    solucao_musculacao: "",
    dificuldade_rotina_saudavel: "",
    meio_conhecimento_jp: "",
    esforco_fisico_desejado: "",
    fisico_desejado: "",
    fisico_desejado_obs: "",
    estetica_corporal: "",
    predominanca_trabalho: "",
    tempo_musculacao: "",
    jornada_trabalho: "",
    alimentacao_diaria: "",
    alimentacao_diaria_obs: "",
    drogas_ilicitas: "",
    drogas_ilicitas_obs: "",
    rotina_trabalho: "",
    consome_bebida_alcoolicas: "",
    consome_bebida_alcoolicas_obs: "",
    disposicao_diaria: "",
    disposicao_diaria_obs: "",
    ingestao_agua: "",
    historico_esportivo: "",
    compulsao_alimentar: "",
    compulsao_alimentar_obs: "",
    frequencia_exercicio_fisico: "",
    frequencia_atividade_fisica: "",
    classificacao_alimentacao: "",
  });

  const handleSubmitPutAnamnese = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formatData = async () => {
      const partes = data.get("data_realizacao_ultimo_backup").split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      uuid: uuid,
      fk_uuid_aluno: useruuid,
      data_realizacao_ultimo_backup: await formatData(),
      data_realizacao_ultimo_backup_obs: data.get(
        "data_realizacao_ultimo_backup_obs"
      ),
      suplementacao: data.get("suplementacao"),
      patologias: data.get("patologias"),
      peso: data.get("peso"),
      idade: data.get("idade"),
      limitacoes: data.get("limitacoes"),
      limitacoes_obs: data.get("limitacoes_obs"),
      setor_atuacao: data.get("setor_atuacao"),
      objetivos_iniciais: data.get("objetivos_iniciais"),
      comprometimento_resultado: data.get("comprometimento_resultado"),
      solucao_musculacao: data.get("solucao_musculacao"),
      dificuldade_rotina_saudavel: data.get("dificuldade_rotina_saudavel"),
      meio_conhecimento_jp: data.get("meio_conhecimento_jp"),
      esforco_fisico_desejado: data.get("esforco_fisico_desejado"),
      fisico_desejado: data.get("fisico_desejado"),
      fisico_desejado_obs: data.get("fisico_desejado_obs"),
      estetica_corporal: data.get("estetica_corporal"),
      predominanca_trabalho: data.get("predominanca_trabalho"),
      tempo_musculacao: data.get("tempo_musculacao"),
      jornada_trabalho: data.get("jornada_trabalho"),
      alimentacao_diaria: data.get("alimentacao_diaria"),
      alimentacao_diaria_obs: data.get("alimentacao_diaria_obs"),
      drogas_ilicitas: data.get("drogas_ilicitas"),
      drogas_ilicitas_obs: data.get("drogas_ilicitas_obs"),
      rotina_trabalho: data.get("rotina_trabalho"),
      consome_bebida_alcoolicas: data.get("consome_bebida_alcoolicas"),
      consome_bebida_alcoolicas_obs: data.get("consome_bebida_alcoolicas_obs"),
      disposicao_diaria: data.get("disposicao_diaria"),
      disposicao_diaria_obs: data.get("disposicao_diaria_obs"),
      ingestao_agua: data.get("ingestao_agua"),
      historico_esportivo: data.get("historico_esportivo"),
      compulsao_alimentar: data.get("compulsao_alimentar"),
      compulsao_alimentar_obs: data.get("compulsao_alimentar_obs"),
      frequencia_exercicio_fisico: data.get("frequencia_exercicio_fisico"),
      frequencia_atividade_fisica: data.get("frequencia_atividade_fisica"),
      classificacao_alimentacao: data.get("classificacao_alimentacao"),
    };

    try {
      await editAnamnese(uuid, form);
      toast.success("Informações alteradas com sucesso!");
      navigate(`/alunos/anamnese/${useruuid}`);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleInputChangeAnamnese = (e) => {
    const { name, value } = e.target;
    setAnamnese((prevUser) => ({ ...prevUser, [name]: value }));
  };

  useEffect(() => {
    async function getData() {
      try {
        const formattedData = async (data) => {
          return format(new Date(data), "dd/MM/yyyy");
        };
        const responseAnamnese = await showAnamnese(uuid);
        setAnamnese({
          data_realizacao_ultimo_backup:
            (await formattedData(
              responseAnamnese.data.data_realizacao_ultimo_backup
            )) || "",
          data_realizacao_ultimo_backup_obs:
            responseAnamnese.data.data_realizacao_ultimo_backup_obs || "",
          suplementacao: responseAnamnese.data.suplementacao || "",
          patologias: responseAnamnese.data.patologias || "",
          peso: responseAnamnese.data.peso || "",
          idade: responseAnamnese.data.idade || "",
          limitacoes: responseAnamnese.data.limitacoes || "",
          limitacoes_obs: responseAnamnese.data.limitacoes_obs || "",
          setor_atuacao: responseAnamnese.data.setor_atuacao || "",
          objetivos_iniciais: responseAnamnese.data.objetivos_iniciais || "",
          comprometimento_resultado:
            responseAnamnese.data.comprometimento_resultado || "",
          solucao_musculacao: responseAnamnese.data.solucao_musculacao || "",
          dificuldade_rotina_saudavel:
            responseAnamnese.data.dificuldade_rotina_saudavel || "",
          meio_conhecimento_jp:
            responseAnamnese.data.meio_conhecimento_jp || "",
          esforco_fisico_desejado:
            responseAnamnese.data.esforco_fisico_desejado || "",
          fisico_desejado: responseAnamnese.data.fisico_desejado || "",
          fisico_desejado_obs: responseAnamnese.data.fisico_desejado_obs || "",
          estetica_corporal: responseAnamnese.data.estetica_corporal || "",
          predominanca_trabalho:
            responseAnamnese.data.predominanca_trabalho || "",
          tempo_musculacao: responseAnamnese.data.tempo_musculacao || "",
          jornada_trabalho: responseAnamnese.data.jornada_trabalho || "",
          alimentacao_diaria: responseAnamnese.data.alimentacao_diaria || "",
          alimentacao_diaria_obs:
            responseAnamnese.data.alimentacao_diaria_obs || "",
          drogas_ilicitas: responseAnamnese.data.drogas_ilicitas || "",
          drogas_ilicitas_obs: responseAnamnese.data.drogas_ilicitas_obs || "",
          rotina_trabalho: responseAnamnese.data.rotina_trabalho || "",
          consome_bebida_alcoolicas:
            responseAnamnese.data.consome_bebida_alcoolicas || "",
          consome_bebida_alcoolicas_obs:
            responseAnamnese.data.consome_bebida_alcoolicas_obs || "",
          disposicao_diaria: responseAnamnese.data.disposicao_diaria || "",
          disposicao_diaria_obs:
            responseAnamnese.data.disposicao_diaria_obs || "",
          ingestao_agua: responseAnamnese.data.ingestao_agua || "",
          historico_esportivo: responseAnamnese.data.historico_esportivo || "",
          compulsao_alimentar: responseAnamnese.data.compulsao_alimentar || "",
          compulsao_alimentar_obs:
            responseAnamnese.data.compulsao_alimentar_obs || "",
          frequencia_exercicio_fisico:
            responseAnamnese.data.frequencia_exercicio_fisico || "",
          frequencia_atividade_fisica:
            responseAnamnese.data.frequencia_atividade_fisica || "",
          classificacao_alimentacao:
            responseAnamnese.data.classificacao_alimentacao || "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    }
    getData();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmitPutAnamnese}
            sx={{ mt: 3 }}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="data_realizacao_ultimo_backup"
                  label="Realização ultimo BKP"
                  name="data_realizacao_ultimo_backup"
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99/99/9999" maskChar="_" />
                    ),
                  }}
                  value={anamnese.data_realizacao_ultimo_backup}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="data_realizacao_ultimo_backup_obs"
                  label="Observações BKP"
                  name="data_realizacao_ultimo_backup_obs"
                  value={anamnese.data_realizacao_ultimo_backup_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="suplementacao"
                  label="Suplementacao"
                  name="suplementacao"
                  value={anamnese.suplementacao}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="patologias"
                  label="Patologias"
                  name="patologias"
                  value={anamnese.patologias}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="peso"
                  label="Peso"
                  name="peso"
                  placeholder="75.9"
                  value={anamnese.peso}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="idade"
                  label="Idade"
                  name="idade"
                  value={anamnese.idade}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="limitacoes">Tem limitações?</InputLabel>
                  <Select
                    labelId="limitacoes"
                    id="limitacoes"
                    sx={{ width: "100%" }}
                    name="limitacoes"
                    value={anamnese.limitacoes}
                    onChange={handleInputChangeAnamnese}
                  >
                    <MenuItem value={"nao"}>Não</MenuItem>
                    <MenuItem value={"sim"}>Sim</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="limitacoes_obs"
                  label="Observações limitações"
                  name="limitacoes_obs"
                  value={anamnese.limitacoes_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="setor_atuacao"
                  label="Setor de atuação"
                  name="setor_atuacao"
                  value={anamnese.setor_atuacao}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="objetivos_iniciais"
                  label="Objetivos iniciais"
                  name="objetivos_iniciais"
                  value={anamnese.objetivos_iniciais}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="comprometimento_resultado"
                  label="Comprometimento com o resultado"
                  name="comprometimento_resultado"
                  placeholder="0 a 10"
                  value={anamnese.comprometimento_resultado}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="solucao_musculacao"
                  label="Musculação é a solução?"
                  name="solucao_musculacao"
                  value={anamnese.solucao_musculacao}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="dificuldade_rotina_saudavel"
                  label="Dificuldades de uma rotina saudavel"
                  name="dificuldade_rotina_saudavel"
                  value={anamnese.dificuldade_rotina_saudavel}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="meio_conhecimento_jp"
                  label="Meio de conhecimento JP"
                  name="meio_conhecimento_jp"
                  placeholder="Instagram, facebook e etc..."
                  value={anamnese.meio_conhecimento_jp}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="esforco_fisico_desejado"
                  label="Esforço fisico desejado"
                  name="esforco_fisico_desejado"
                  value={anamnese.esforco_fisico_desejado}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="fisico_desejado"
                  label="Fisico desejado"
                  name="fisico_desejado"
                  value={anamnese.fisico_desejado}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="fisico_desejado_obs"
                  label="Observações fisico desejado"
                  name="fisico_desejado_obs"
                  value={anamnese.fisico_desejado_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="estetica_corporal"
                  label="Estetica corporal"
                  name="estetica_corporal"
                  value={anamnese.estetica_corporal}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="predominanca_trabalho"
                  label="Predominancia de posição no trabalho"
                  name="predominanca_trabalho"
                  value={anamnese.predominanca_trabalho}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="tempo_musculacao"
                  label="Tempo que ja treina?"
                  name="tempo_musculacao"
                  value={anamnese.tempo_musculacao}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="jornada_trabalho"
                  label="Jornada de trabalho"
                  name="jornada_trabalho"
                  value={anamnese.jornada_trabalho}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="alimentacao_diaria"
                  label="Alimentação diária"
                  name="alimentacao_diaria"
                  value={anamnese.alimentacao_diaria}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="alimentacao_diaria_obs"
                  label="Observações alimentação diária"
                  name="alimentacao_diaria_obs"
                  value={anamnese.alimentacao_diaria_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="drogas_ilicitas"
                  label="Uso de drogas ilicitas"
                  name="drogas_ilicitas"
                  value={anamnese.drogas_ilicitas}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="drogas_ilicitas_obs"
                  label="Observações uso de drogas ilicitas"
                  name="drogas_ilicitas_obs"
                  value={anamnese.drogas_ilicitas_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="rotina_trabalho"
                  label="Descreva a rotina de trabalho"
                  name="rotina_trabalho"
                  value={anamnese.rotina_trabalho}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="consome_bebida_alcoolicas"
                  label="Uso de bebidas alcoolicas"
                  name="consome_bebida_alcoolicas"
                  value={anamnese.consome_bebida_alcoolicas}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="consome_bebida_alcoolicas_obs"
                  label="Observações uso de bebidas alcoolicas"
                  name="consome_bebida_alcoolicas_obs"
                  value={anamnese.consome_bebida_alcoolicas_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="disposicao_diaria"
                  label="Disposição diária"
                  name="disposicao_diaria"
                  value={anamnese.disposicao_diaria}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="disposicao_diaria_obs"
                  label="Observações da disposição diária"
                  name="disposicao_diaria_obs"
                  value={anamnese.disposicao_diaria_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="ingestao_agua"
                  label="Ingestão de água"
                  name="ingestao_agua"
                  value={anamnese.ingestao_agua}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="historico_esportivo"
                  label="Histórico esportivo"
                  name="historico_esportivo"
                  value={anamnese.historico_esportivo}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="frequencia_exercicio_fisico"
                  label="Frequência de exercicio fisico"
                  name="frequencia_exercicio_fisico"
                  value={anamnese.frequencia_exercicio_fisico}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="frequencia_atividade_fisica"
                  label="Frequência de atividade fisica"
                  name="frequencia_atividade_fisica"
                  value={anamnese.frequencia_atividade_fisica}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="compulsao_alimentar"
                  label="Compulsão alimentar"
                  name="compulsao_alimentar"
                  value={anamnese.compulsao_alimentar}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  id="compulsao_alimentar_obs"
                  label="Observações da compulsão alimentar"
                  name="compulsao_alimentar_obs"
                  value={anamnese.compulsao_alimentar_obs}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="classificacao_alimentacao"
                  label="Classificação alimentar"
                  name="classificacao_alimentacao"
                  value={anamnese.classificacao_alimentacao}
                  onChange={handleInputChangeAnamnese}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} mt={3} mb={3}>
              <Button
                sx={{ marginRight: "8px" }}
                onClick={() => {
                  navigate(`/alunos/anamnese/${useruuid}`);
                }}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Gravar
              </Button>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
