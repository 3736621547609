import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormControl,
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import forgotPasswordService from "./forgotPasswordService";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function ResetPassword() {
  const { resetPassword } = forgotPasswordService();
  const location = useLocation();
  const { email } = queryString.parse(location.search);
  const navigate = useNavigate();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = {
      email: email,
      token: token,
      password: data.get("password"),
      password_confirmation: data.get("password_confirmation"),
    };

    try {
      await resetPassword(form);
      navigate("/login");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Typography component="p" variant="subtitle1" align="center">
            Digite a nova senha para acessar o sistema
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: "100%" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Senha</InputLabel>
                  <OutlinedInput
                    required
                    name="password"
                    label="Senha"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Confirme a senha</InputLabel>
                  <OutlinedInput
                    required
                    name="password_confirmation"
                    label="Senha"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    type={showPassword ? "text" : "password"}
                    id="password_confirmation"
                    autoComplete="password_confirmation"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Enviar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Voltar para o Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
