import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };
  const postUser = async (form) => {
    const { data } = await api.post("/portal/usuario", form, { headers });
    return data;
  };

  const postFunc = async (form) => {
    const { data } = await api.post("/portal/funcionario", form, { headers });
    return data;
  };

  const editUser = async (form, uuid) => {
    const { data } = await api.put(`/portal/usuario/${uuid}`, form, {
      headers,
    });
    return data;
  };

  const getFunc = async () => {
    const { data } = await api.get("/portal/funcionario", { headers });
    return data;
  };

  const showUser = async (uuid) => {
    const { data } = await api.get(`/portal/usuario/${uuid}`, { headers });
    return data;
  };

  const deleteFunc = async (uuid) => {
    const { data } = await api.delete(`/portal/funcionario/${uuid}`, {
      headers,
    });
    return data;
  };

  return {
    postUser,
    postFunc,
    editUser,
    getFunc,
    showUser,
    deleteFunc,
  };
}
