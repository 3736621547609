import React from "react";
import { Grid, Paper, Typography, Tooltip } from "@mui/material";

export const renderizaMedias = (dadosMedia) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={2} sx={{ mb: 3 }}>
      {Object.entries(dadosMedia).map(([key, value]) => (
        <Grid item key={key} xs={6} md={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: "16px",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "#212B2F",
                fontWeight: "400",
                fontSize: "1.5rem",
                lineHeight: "2",
              }}
            >
              {formatarChave(key)}
            </Typography>
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "1.5",
                  }}
                >
                  {value}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

const formatarChave = (chave) => {
  switch (chave) {
    case "alimentacao":
      return "Alimentação";
    case "frequencia_motivacao":
      return "Frequência de Motivação";
    case "autoestima":
      return "Autoestima";
    case "disposicao":
      return "Disposição";
    case "ingestaoAgua":
      return "Ingestão de Água";
    case "ingestaoBebidaAlcoolica":
      return "Ingestão de Bebida Alcoólica";
    case "intensidadeTreino":
      return "Intensidade do Treino";
    case "organizacao":
      return "Organização";
    case "tabagismo":
      return "Tabagismo";
    case "dores":
      return "Dores";
    case "doencas":
      return "Doenças";
    case "sono_qualitativo":
      return "Qualidade do Sono";
    case "sono_quantitativo":
      return "Quantidade do Sono";
    default:
      return chave;
  }
};

export default renderizaMedias;
