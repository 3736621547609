import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./userReducer"; // Seu root reducer

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer
  // applyMiddleware(...middleware) // Se você estiver usando middleware
);

const persistor = persistStore(store);

export { store, persistor };
