import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import statusService from "../statusService";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

export default function ModalStatus({ openModal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };
  const [status, setStatus] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [openFormStatus, setOpenFormStatus] = useState(false);
  const colors = [
    { nome: "Vermelho", hex: "#d32f2f" },
    { nome: "Verde", hex: "#2e7d32" },
    { nome: "Azul", hex: "#1976D2" },
    { nome: "Amarelo", hex: "#FFFF00" },
    { nome: "Ciano", hex: "#00FFFF" },
    { nome: "Magenta", hex: "#FF00FF" },
    { nome: "Laranja", hex: "#FFA500" },
    { nome: "Roxo", hex: "#800080" },
    { nome: "Rosa", hex: "#FFC0CB" },
    { nome: "Azul-Verde", hex: "#008080" },
    { nome: "Marrom", hex: "#A52A2A" },
  ];
  const { postStatus, getStatus, deleteStatus } = statusService();

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSelectedDescription(event.target.value);
  };

  const handleSubmit = async () => {
    const form = {
      descricao: selectedDescription,
      cor: selectedColor,
    };

    try {
      await postStatus(form);
      toast.success("Status criado com sucesso!");
      setSelectedColor("");
      setSelectedDescription("");
      handleClose();
      window.location.reload();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getStatus();
        setStatus(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const handleDeleteStatus = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o status?"
    );

    if (confirmRemoval) {
      try {
        await deleteStatus(uuid);
        toast.success("Status removido com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error("Desvincule o(s) eventos(s) desse status para excluí-lo");
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const uuidsNoDelete = [
    "8c43f6c4-5b3f-4746-bc17-b8ed6604888c",
    "8df58c62-3594-44c6-a51e-287c9422969a",
    "b51266b1-0fbf-45a7-8708-d461dcc2162c",
  ];

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <Grid item xs={12}>
          <Typography
            sx={{ textAlign: "center", mb: 2 }}
            variant="h6"
            component="div"
          >
            Status
          </Typography>
          <Divider />
          {!openFormStatus ? (
            <List>
              {status.map((s) => {
                return (
                  <ListItem
                    key={s.uuid}
                    secondaryAction={
                      !uuidsNoDelete.includes(s.uuid) ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteStatus(s.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {s.descricao}{" "}
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          background: `${s.cor}`,
                        }}
                      ></div>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Container>
              <Grid container rowSpacing={3} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Status"
                    value={selectedDescription}
                    onChange={handleDescriptionChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Selecione a cor</InputLabel>
                    <Select value={selectedColor} onChange={handleColorChange}>
                      {colors.map((color, index) => (
                        <MenuItem
                          key={index}
                          value={color.hex}
                          style={{ backgroundColor: color.hex }}
                        >
                          {color.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <Button variant="contained" onClick={handleSubmit}>
                    Gravar
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenFormStatus(false);
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Container>
          )}
          {!openFormStatus && (
            <Grid sx={{ display: "flex", gap: "8px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenFormStatus(true);
                }}
              >
                Cadastrar
              </Button>
              <Button type="submit" variant="text" onClick={handleClose}>
                Voltar
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
