import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: "https://api.jpvieirapersonal.com/api",
});

const headers = {
  Authorization: `Bearer ${Cookies.get("jwt")}`,
};

api.defaults.withCredentials = true;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 419) {
        api.get("/csrf-cookie").then(() => {
          api
            .post(
              "/logout-api",
              {},
              {
                headers,
              }
            )
            .then(() => {
              Cookies.remove("logged", { path: "/" });
              window.location.href = "/login";
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }

      if(error.response.status === 401) {
        Cookies.remove("logged", { path: "/" });
        window.location.href = "/login";
      }
    }

    if (error.response.status === 404) {
      toast.error(
        "Ocorreu algum problema, caso persista, entre em contato com o administrador"
      );
      Cookies.remove("logged");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export { api };
