import { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import forgotPasswordService from "./forgotPasswordService";
import { toast } from "react-toastify";
import Logo from "../../assets/logo.png";

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const { forgotPassword } = forgotPasswordService();
  const [sucessMessage, setSucessMessage] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = { email: data.get("email") };
    try {
      const response = await forgotPassword(form);
      setSucessMessage(response.status);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "128px", marginBottom: "16px" }}
          />
          <Typography component="p" variant="subtitle1" align="center">
            Digite o endereço de e-mail da sua conta de usuário e enviaremos um
            link de redefinição de senha.
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: "100%" }}
          >
            {sucessMessage ? (
              <Typography
                component="h3"
                variant="h5"
                align="center"
                color="primary"
              >
                {sucessMessage}
              </Typography>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Enviar
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Voltar para o Login
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
