import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import avisosService from "../../pages/Avisos/avisosService";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import funcoes from "../../composables/funcoes";

export default function UserInformation() {
  const [avisos, setAvisos] = useState();
  const { getAvisos } = avisosService();
  const { endSession } = funcoes();
  const userData = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAvisos(userData.uuid);
        setAvisos(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => {
          handleOpen();
        }}
      >
        {avisos?.length === 0 ? (
          <AccountCircleIcon />
        ) : (
          <Badge badgeContent={"!"} color="secondary">
            <AccountCircleIcon />
          </Badge>
        )}
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ width: 56, height: 56 }}>
                {userData.nome.charAt(0)}
              </Avatar>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5">{userData.nome}</Typography>
              <Typography variant="caption">{userData.email}</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: avisos !== null ? "center" : "flex-start",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  bgcolor: "#1976d2",
                }}
              >
                {avisos?.length !== 0 ? (
                  <>
                    <Typography variant="p" color="#fafafa">
                      Voce tem mensagens não lidas
                    </Typography>
                    <div>
                      <Button
                        sx={{
                          color: "#fafafa",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          navigate(`/avisos/${userData.uuid}`);
                        }}
                      >
                        Ler
                      </Button>
                    </div>
                  </>
                ) : (
                  <Typography variant="p" color="#fafafa">
                    Voce não tem mensagens
                  </Typography>
                )}
              </Paper>
            </Grid>

            <Grid sx={{ display: "flex", gap: "8px", mt: 3 }}>
              <Button variant="text" onClick={handleClose}>
                Voltar
              </Button>
              <Button
                type="submit"
                color="error"
                variant="contained"
                onClick={endSession}
              >
                Sair
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
