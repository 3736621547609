import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ThreePIcon from "@mui/icons-material/ThreeP";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Funcoes from "../../composables/funcoes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MainListItems = () => {
  const userData = useSelector((state) => state.userData);
  const { endSession } = Funcoes();
  const navigate = useNavigate();

  if (userData.funcionario) {
    return (
      <React.Fragment>
        <ListItemButton onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/funcionarios")}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Funcionários" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/alunos")}>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Alunos" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/feedback")}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback Semanal" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate(`/avisos/${userData.uuid}`)}>
          <ListItemIcon>
            <ThreePIcon />
          </ListItemIcon>
          <ListItemText primary="Mensagens" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/graficos")}>
          <ListItemIcon>
            <InsertChartIcon />
          </ListItemIcon>
          <ListItemText primary="Gráficos" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/calendario")}>
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText primary="Calendário" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/educacional")}>
          <ListItemIcon>
            <HandshakeIcon />
          </ListItemIcon>
          <ListItemText primary="Portal educacional" />
        </ListItemButton>
        <ListItemButton onClick={endSession}>
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ListItemButton onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate(`/feedback/${userData.uuid}`)}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback Semanal" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/calendario")}>
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText primary="Calendário" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate(`/avisos/${userData.uuid}`)}>
          <ListItemIcon>
            <ThreePIcon />
          </ListItemIcon>
          <ListItemText primary="Mensagens" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate(`/arquivos/${userData.uuid}`)}>
          <ListItemIcon>
            <DriveFolderUploadIcon />
          </ListItemIcon>
          <ListItemText primary="Arquivos" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/educacional")}>
          <ListItemIcon>
            <HandshakeIcon />
          </ListItemIcon>
          <ListItemText primary="Portal educacional" />
        </ListItemButton>
        <ListItemButton onClick={endSession}>
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </React.Fragment>
    );
  }
};

export default MainListItems;
