import {
  Modal,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import avisoService from "../avisosService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ModalAdmin({
  openModal,
  handleClose,
  selectedAlunoUuid,
  selectedAlunoName,
  selectedAlunoTelefone,
}) {
  const [message, setMessage] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { postAviso } = avisoService();
  const userData = useSelector((state) => state.userData);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setButtonDisabled(false);
  };

  const handleSubmit = async () => {
    const form = {
      descricao: message,
      fk_uuid_destinatario: selectedAlunoUuid,
      fk_uuid_remetente: userData.uuid,
    };

    try {
      await postAviso(form);
      toast.success("Mensagem enviada com sucesso!");
      handleClose();
      setMessage();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="p">
                Recado para o(a) aluno(a):{" "}
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {selectedAlunoName}
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                mt: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                multiline
                value={message}
                onChange={handleMessageChange}
              />
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href={`https://api.whatsapp.com/send?phone=${selectedAlunoTelefone}&text=${encodeURIComponent(
                    message
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    color="success"
                    disabled={buttonDisabled}
                    endIcon={<WhatsAppIcon />}
                    onClick={() => {
                      handleSubmit();
                      setMessage();
                      handleClose();
                      setButtonDisabled(true);
                    }}
                  >
                    Enviar
                  </Button>
                </a>
                <Button
                  onClick={() => {
                    setMessage();
                    handleClose();
                    setButtonDisabled(true);
                  }}
                  sx={{ mt: 2 }}
                >
                  Voltar
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Modal>
  );
}
