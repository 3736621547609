import Cookies from "js-cookie";
import { api } from "../../boot/axios";
export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };
  const postVideo = async (form) => {
    const { data } = await api.post("/portal/educacao", form, { headers });
    return data;
  };
  const getVideos = async () => {
    const { data } = await api.get("/portal/educacao", { headers });
    return data;
  };

  const getVideo = async (uuid) => {
    const { data } = await api.get(`/portal/educacao/${uuid}`, { headers });
    return data;
  };

  const putVideo = async (uuid, form) => {
    const { data } = await api.put(`/portal/educacao/${uuid}`, form, {
      headers,
    });
    return data;
  };

  const deleteVideo = async (uuid) => {
    const { data } = await api.delete(`/portal/educacao/${uuid}`, {
      headers,
    });
    return data;
  };

  const postCategoria = async (form) => {
    const { data } = await api.post("/portal/categorias", form, { headers });
    return data;
  };

  const getCategorias = async () => {
    const { data } = await api.get("/portal/categorias", { headers });
    return data;
  };

  const deleteCategoria = async (uuid) => {
    const { data } = await api.delete(`/portal/categorias/${uuid}`, {
      headers,
    });
    return data;
  };

  const getCategoriaAluno = async () => {
    const { data } = await api.get(`/portal/educacao/aluno`, {
      headers,
    });
    return data;
  };

  return {
    postVideo,
    getVideos,
    getVideo,
    putVideo,
    deleteVideo,
    getCategoriaAluno,
    getCategorias,
    postCategoria,
    deleteCategoria,
  };
}
