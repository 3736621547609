import { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Modal,
  CssBaseline,
  Toolbar,
  Box,
  Grid,
  Divider,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import arquivoService from "../arquivoService";
import { useParams, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import funcoes from "../../../composables/funcoes";
const defaultTheme = createTheme();

export default function UploadArchive() {
  const { getIconByFileType } = funcoes();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [arquivos, setArquivos] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const filesElement = useRef(null);
  const { postArchive, showArchives, deleteArchive } = arquivoService();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const sendFile = async () => {
    const dataForm = new FormData();
    dataForm.append(
      "fk_uuid_funcionario",
      "46e1393d-9162-46b0-9db7-309468186425"
    );
    dataForm.append("fk_uuid_aluno", uuid);

    for (const file of filesElement.current.files) {
      if (file.size > 0) {
        dataForm.append("arquivos[]", file);
      } else {
        console.warn(`O arquivo ${file.name} está vazio e será excluído.`);
      }
    }

    try {
      await postArchive(dataForm);
      toast.success("Arquivo(s) enviado(s) com sucesso!");
      setOpen(false);
      setUpdateFlag(!updateFlag);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleDelete = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja excluir o arquivo?"
    );

    if (confirmRemoval) {
      try {
        await deleteArchive(uuid);
        toast.success("Arquivo removido com sucesso!");
        setUpdateFlag(!updateFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleDownload = (caminhoArquivo) => {
    const link = `https://api.jpvieirapersonal.com/storage/${caminhoArquivo}`;
    window.open(link, "_blank");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await showArchives(uuid);
        setArquivos(response.data);
        setIsLoading(false);
      } catch (error) {}
    }
    getData();
  }, [updateFlag]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container>
            <Grid item xs={12}>
              <Button
                onClick={handleOpen}
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
              >
                Enviar Arquivo
              </Button>
            </Grid>
            <Divider sx={{ p: 2, width: "100%" }} />

            <Grid item xs={12} mt={2}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    mr: 4,
                    width: "100%",
                  },
                }}
              >
                <Grid
                  container
                  sx={{ width: "100%" }}
                  rowSpacing={3}
                  columnSpacing={3}
                >
                  {arquivos.map((arquivo) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={2}
                      sx={{ width: "100%" }}
                    >
                      <div
                        key={arquivo.uuid}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d9d9d9",
                            height: "150px",
                            borderRadius: "10px",
                            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-end",
                              marginRight: "-100px",
                              position: "relative",
                              right: "-10px",
                              bottom: "-10px",
                            }}
                          >
                            {getIconByFileType(arquivo.nome_arquivo)}
                          </div>
                        </div>

                        <div>
                          <Typography
                            variant="p"
                            style={{
                              marginBottom: 10,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "wrap",
                            }}
                          >
                            {arquivo.nome_arquivo}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleDownload(arquivo.caminho_arquivo)
                              }
                            >
                              Download
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDelete(arquivo.uuid)}
                            >
                              Excluir
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Button
              onClick={() => {
                navigate("/alunos");
              }}
              sx={{ mt: 2 }}
            >
              Voltar
            </Button>
          </Grid>
        </Container>
      </ThemeProvider>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <input type="file" multiple ref={filesElement} />
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={sendFile}
          >
            Enviar Arquivo
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
