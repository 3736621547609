import { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Box,
  Grid,
  Typography,
  Modal,
  TextField,
  Divider,
  List,
  ListItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import educacaoService from "../educacaoService";
import Funcoes from "../../../composables/funcoes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import youTubeEmbed from "../../../components/VideoPlayer";

export default function DashboardFuncView() {
  const {
    getCategoriaAluno,
    getVideo,
    postVideo,
    putVideo,
    deleteVideo,
    getCategorias,
    postCategoria,
    deleteCategoria,
  } = educacaoService();

  const { Markdown } = Funcoes();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videosPorCategoria, setVideosPorCategoria] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditingVideo(null);
    setFormValues({
      titulo: "",
      descricao: "",
      link: "",
      fk_uuid_categoria: "",
      categoria: "",
    });
    setCategoriaSelecionada({ fk_uuid_categoria: "", categoria: "" });
  };
  const [editingVideo, setEditingVideo] = useState(null);
  const [formValues, setFormValues] = useState({
    titulo: "",
    descricao: "",
    link: "",
    fk_uuid_categoria: "",
    categoria: "",
  });

  const handleSubmitPostVideo = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = {
      titulo: data.get("titulo"),
      descricao: data.get("descricao"),
      link: data.get("link"),
      fk_uuid_categoria: categoriaSelecionada.fk_uuid_categoria,
      categoria: categoriaSelecionada.categoria,
    };

    try {
      await postVideo(form);
      toast.success("Video cadastrado com sucesso!");
      setOpen(false);
      setUpdateFlag((prevFlag) => !prevFlag);
      setCategoriaSelecionada({ fk_uuid_categoria: "", categoria: "" });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleDeleteVideo = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o video?"
    );

    if (confirmRemoval) {
      try {
        await deleteVideo(uuid);
        toast.success("Video removido com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleEditVideo = async (uuid) => {
    try {
      const response = await getVideo(uuid);
      setEditingVideo(response.data);
      setFormValues(response.data);
      setCategoriaSelecionada({
        fk_uuid_categoria: response.data.fk_uuid_categoria,
        categoria: response.data.categoria,
      });
      setOpen(true);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmitEditVideo = async (event) => {
    event.preventDefault();
    try {
      const form = {
        ...formValues,
        fk_uuid_categoria: categoriaSelecionada.fk_uuid_categoria,
        categoria: categoriaSelecionada.categoria,
      };
      await putVideo(editingVideo.uuid, form);
      setOpen(false);
      setUpdateFlag((prevFlag) => !prevFlag);
      toast.success("Informações editadas com sucesso!");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getCategoriaAluno();
        // Converte o objeto de categorias em uma matriz de objetos
        const categoriasArray = Object.entries(response.data).map(
          ([categoria, videos]) => ({
            categoria,
            videos,
          })
        );
        setVideosPorCategoria(categoriasArray);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  // Categorias

  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState({
    fk_uuid_categoria: "",
    categoria: "",
  });
  const [descricaoCategoria, setDescricaoCategoria] = useState("");
  const [openModalCategoria, setOpenModalCategoria] = useState(false);
  const [openFormCategoria, setOpenFormCategoria] = useState(false);
  const handleOpenModalCategoria = () => setOpenModalCategoria(true);
  const handleCloseModalCategoria = () => {
    setOpenModalCategoria(false);
    setOpenFormCategoria(false);
  };
  const handleCategoriaSelecionada = (event) => {
    const { value } = event.target;
    const selectedIndex = event.target.selectedIndex;
    const nomeCategoria =
      selectedIndex >= 0
        ? event.target[selectedIndex].getAttribute("name")
        : "";
    setCategoriaSelecionada({
      fk_uuid_categoria: value,
      categoria: nomeCategoria,
    });
  };
  const handleDescricaoCategoriaChange = (event) => {
    setDescricaoCategoria(event.target.value);
  };
  const handleOpenFormCategoria = () => {
    setOpenFormCategoria(true);
  };

  const handleDeleteCategoria = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover a categoria?"
    );

    if (confirmRemoval) {
      try {
        await deleteCategoria(uuid);
        toast.success("Categoria removida com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(
            "Desvincule o(s) video(s) dessa categoria para excluí-la"
          );
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleSubmitPostCategoria = async () => {
    const form = {
      descricao: descricaoCategoria,
    };

    try {
      await postCategoria(form);
      toast.success("Categoria cadastrada com sucesso!");
      setUpdateFlag((prevFlag) => !prevFlag);
      setOpenFormCategoria(false);
      setDescricaoCategoria("");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getCategorias();
        setCategorias(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #d9d9d9",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    borderRadius: "20px",
  };

  return (
    <>
      <Container maxWidth="xl">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container>
          <Grid item xs={12} mt={3} mb={3}>
            <Button
              sx={{ mr: 2 }}
              onClick={handleOpen}
              variant="contained"
              color="primary"
            >
              Adicionar video
            </Button>
            <Button
              onClick={handleOpenModalCategoria}
              variant="contained"
              color="warning"
            >
              Categorias
            </Button>
          </Grid>
          {videosPorCategoria.map((categArray, index) => {
            const isLastAccordion = index === videosPorCategoria.length - 1;
            return (
              <Accordion
                defaultExpanded={index === 0}
                key={index}
                sx={{
                  width: "100%",
                  ...(isLastAccordion && { mb: 3 }),
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  color="text.secondary"
                  sx={{
                    fontWeight: 700,
                    opacity: "0.6",
                    fontSize: "20px",
                  }}
                >
                  {categArray.categoria}
                </AccordionSummary>
                <AccordionDetails>
                  {categArray.videos.map((video) => {
                    return (
                      <Grid key={video.uuid}>
                        <Grid item xs={12} mt={2} container spacing={2}>
                          <Grid
                            item
                            lg={6}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              gap: "30px",
                            }}
                          >
                            <div>
                              <Typography variant="h4">
                                {video.titulo}
                              </Typography>
                              <Typography>
                                {Markdown(video.descricao)}
                              </Typography>
                            </div>
                            <Grid sx={{ display: "flex", gap: "10px" }}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleEditVideo(video.uuid);
                                }}
                              >
                                Editar
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  handleDeleteVideo(video.uuid);
                                }}
                              >
                                Excluir
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <div
                              style={{
                                overflow: "hidden",
                                position: "relative",
                                width: "100%",
                              }}
                            >
                              <iframe
                                title="Vídeo do YouTube"
                                style={{
                                  borderRadius: "24px",
                                  height: "300px",
                                  width: "100%",
                                }}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                src={youTubeEmbed(video.link)}
                                frameBorder="0"
                              ></iframe>
                            </div>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 3 }} />
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Container>
      
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={style}
          component="form"
          onSubmit={
            editingVideo ? handleSubmitEditVideo : handleSubmitPostVideo
          }
        >
          <Grid item xs={12} md={3} sx={{ width: "100%" }}>
            <TextField
              required
              fullWidth
              id="titulo"
              label="Titulo"
              name="titulo"
              value={formValues.titulo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ width: "100%" }}>
            <TextField
              required
              fullWidth
              multiline
              id="descricao"
              label="Descrição"
              name="descricao"
              value={formValues.descricao}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ width: "100%" }}>
            <TextField
              required
              fullWidth
              id="link"
              label="Link do vídeo"
              name="link"
              value={formValues.link}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              mt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label-categoria">
                Selecione a categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-categoria"
                id="demo-simple-select-categoria"
                label="Selecione a categoria"
                onChange={handleCategoriaSelecionada}
                value={categoriaSelecionada.fk_uuid_categoria}
              >
                <MenuItem value="">Selecione</MenuItem>
                {categorias.map((cat) => (
                  <MenuItem
                    value={cat.uuid}
                    key={cat.uuid}
                    name={cat.descricao}
                  >
                    {cat.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "8px" }}>
            <Button type="submit" variant="contained">
              Gravar
            </Button>
            <Button type="submit" variant="text" onClick={handleClose}>
              Voltar
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openModalCategoria} onClose={handleCloseModalCategoria}>
        <Box sx={style}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center", mb: 2 }}
              variant="h6"
              component="div"
            >
              Categorias
            </Typography>
            <Divider />
            {openFormCategoria ? (
              <Box>
                <Grid item xs={12} md={3} sx={{ width: "100%" }}>
                  <TextField
                    required
                    fullWidth
                    id="categoria"
                    label="Categoria"
                    name="categoria"
                    value={descricaoCategoria}
                    onChange={handleDescricaoCategoriaChange}
                  />
                </Grid>
              </Box>
            ) : (
              <List>
                {categorias.map((cat) => {
                  return (
                    <ListItem
                      key={cat.uuid}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteCategoria(cat.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      {cat.descricao}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Grid>
          <Grid sx={{ display: "flex", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={
                openFormCategoria
                  ? handleSubmitPostCategoria
                  : () => {
                      handleOpenFormCategoria();
                    }
              }
            >
              Cadastrar
            </Button>
            <Button
              type="submit"
              variant="text"
              onClick={handleCloseModalCategoria}
            >
              Voltar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
