import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Switch,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate, useParams } from "react-router-dom";
import funcService from "../funcService";
import { format } from "date-fns";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function FormEditFunc() {
  const navigate = useNavigate();
  const { showUser, editUser } = funcService();
  const { uuid } = useParams();
  const [user, setUser] = useState({
    nome: "",
    email: "",
    genero: "",
    cpf: "",
    data_nascimento: "",
    telefone: "",
    ativo: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formatData = async () => {
      const partes = data.get("data_nascimento").split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      nome: data.get("nome"),
      email: data.get("email"),
      sexo: data.get("genero"),
      cpf: data.get("cpf").replace(/[^0-9]/g, ""),
      data_nascimento: await formatData(),
      telefone: data.get("telefone").replace(/\s/g, ""),
      ativo: data.get("ativo") ? 1 : 0,
    };

    try {
      await editUser(form, uuid);
      toast.success("Funcionário(a) editado(a) com sucesso!");
      navigate("/funcionarios");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await showUser(uuid);
        const userData = response.data;
        const formattedDataNascimento = userData.data_nascimento
          ? format(new Date(userData.data_nascimento), "dd/MM/yyyy")
          : "";
        setUser({
          nome: userData.nome || "",
          email: userData.email || "",
          genero: userData.sexo || "F",
          cpf: userData.cpf || "",
          data_nascimento: formattedDataNascimento,
          telefone: userData.telefone || "",
          ativo: userData.ativo || true,
        });
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        width: "100%",
      }}
    >
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="nome"
                  label="Nome"
                  name="nome"
                  value={user.nome}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid item xs={12}>
                  <FormLabel id="genero">Genero</FormLabel>
                </Grid>
                <FormControlLabel
                  value="F"
                  control={<Radio checked={user.genero === "F"} />}
                  label="Feminino"
                  name="genero"
                  onChange={handleInputChange}
                />
                <FormControlLabel
                  value="M"
                  control={<Radio checked={user.genero === "M"} />}
                  label="Masculino"
                  name="genero"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  value={user.cpf}
                  onChange={handleInputChange}
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask
                        {...props}
                        mask="999.999.999-99"
                        maskChar="_"
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="data_nascimento"
                  label="Nascimento"
                  name="data_nascimento"
                  value={user.data_nascimento}
                  onChange={handleInputChange}
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99/99/9999" maskChar="_" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  fullWidth
                  id="telefone"
                  label="Contato"
                  name="telefone"
                  value={user.telefone}
                  onChange={handleInputChange}
                  InputProps={{
                    inputComponent: (props) => (
                      <InputMask {...props} mask="99 999999999" maskChar="_" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  id="ativo"
                  required
                  control={<Switch checked={user.ativo} />}
                  label="Ativo"
                  name="ativo"
                  onChange={() =>
                    setUser((prevUser) => ({
                      ...prevUser,
                      ativo: !prevUser.ativo,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} mt={8}>
              <Button
                sx={{ marginRight: "8px" }}
                onClick={() => {
                  navigate("/funcionarios");
                }}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Gravar
              </Button>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
