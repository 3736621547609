import { api } from "../../boot/axios";
import UseApi from "../../composables/UseApi";

export default function exampleService() {
  const { csrfCookie } = UseApi();
  const post = async (form) => {
    await csrfCookie();

    const { data } = await api.post("/login-api", form);
    return data;
  };

  const postNotification = async (token, uuid) => {
    const { data } = await api.post("/notificacao", {
      token: token,
      fk_uuid_usuario: uuid,
    });
    return data;
  };

  return {
    post,
    postNotification,
  };
}
