import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  CssBaseline,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  ButtonGroup,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import MessageIcon from "@mui/icons-material/Message";
import { useNavigate } from "react-router-dom";
import alunoService from "../alunoService";
import ModalAdmin from "../../Avisos/Dashboard/modalAdmin";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

const handleSubmit = (event) => {
  event.preventDefault();
};

export default function Alunos() {
  const { getAlunos, deleteAluno } = alunoService();
  const [originalAlunos, setOriginalAlunos] = useState([]);
  const navigate = useNavigate();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAlunoUuid, setSelectedAlunoUuid] = useState(null);
  const [selectedAlunoName, setSelectedAlunoName] = useState(null);
  const [selectedAlunoTelefone, setSelectedAlunoTelefone] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [filter, setFilter] = useState({
    nome: "",
    telefone: "",
    email: "",
  });

  useEffect(() => {
    async function getData() {
      try {
        const response = await getAlunos();
        setOriginalAlunos(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
    getData();
  }, [updateFlag]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const removeAluno = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja remover o aluno(a)?"
    );

    if (confirmRemoval) {
      try {
        await deleteAluno(uuid);
        toast.success("Aluno(a) removido com sucesso!");
        setUpdateFlag((prevFlag) => !prevFlag);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const editAluno = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja editar o aluno(a)?"
    );

    if (confirmRemoval) {
      try {
        navigate(`/alunos/cadastro/${uuid}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  const openArchive = async (uuid) => {
    const confirmRemoval = window.confirm(
      "Tem certeza que deseja abrir os arquivos do(a) aluno(a)?"
    );

    if (confirmRemoval) {
      try {
        navigate(`/arquivos/${uuid}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  const openAnmanese = async (uuid) => {
    const confirm = window.confirm(
      "Tem certeza que deseja abrir a anamnese do(a) aluno(a)?"
    );

    if (confirm) {
      try {
        navigate(`/alunos/anamnese/${uuid}`);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.error(error.message);
        }
      }
    }
  };

  const handleOpenModal = (uuid, name, telefone) => {
    setSelectedAlunoUuid(uuid);
    setSelectedAlunoName(name);
    setSelectedAlunoTelefone(telefone);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setSelectedAlunoUuid(null);
    setOpenModal(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate("/alunos/cadastro");
                }}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ backgroundColor: "#1976D2" }}
                >
                  <Typography style={{ color: "#fff" }}>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoComplete="nome"
                            value={filter.nome}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="telefone"
                            label="Telefone"
                            name="telefone"
                            autoComplete="telefone"
                            value={filter.telefone}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            value={filter.email}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Telefone
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 700, backgroundColor: "#fafafa" }}
                        align="right"
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {originalAlunos
                      .filter((aluno) => {
                        return (
                          aluno.nome
                            .toLowerCase()
                            .includes(filter.nome.toLowerCase()) &&
                          aluno.telefone.includes(filter.telefone) &&
                          aluno.email
                            .toLowerCase()
                            .includes(filter.email.toLowerCase())
                        );
                      })
                      .map((i) => (
                        <TableRow
                          key={i.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {i.nome}
                          </TableCell>
                          <TableCell align="right">{i.telefone}</TableCell>
                          <TableCell align="right">{i.email}</TableCell>
                          <TableCell align="right">
                            <ButtonGroup variant="contained" size="large">
                              <Button
                                onClick={() => {
                                  editAluno(i.uuid);
                                }}
                                title="Editar"
                                startIcon={<EditIcon />}
                              ></Button>
                              <Button
                                onClick={() => {
                                  openAnmanese(i.uuid);
                                }}
                                title="Anamnese"
                                startIcon={<ContactPageIcon />}
                              ></Button>
                              <Button
                                onClick={() => {
                                  openArchive(i.uuid);
                                }}
                                title="Arquivos"
                                startIcon={<DriveFolderUploadIcon />}
                              ></Button>
                              <Button
                                onClick={() => {
                                  handleOpenModal(i.uuid, i.nome, i.telefone);
                                }}
                                title="Mensagem"
                                startIcon={<MessageIcon />}
                              ></Button>
                              <Button
                                onClick={() => {
                                  removeAluno(i.uuid);
                                }}
                                title="Remover"
                                startIcon={<RemoveCircleIcon />}
                              ></Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ModalAdmin
        openModal={openModal}
        handleClose={handleCloseModal}
        selectedAlunoUuid={selectedAlunoUuid}
        selectedAlunoName={selectedAlunoName}
        selectedAlunoTelefone={selectedAlunoTelefone}
      />
    </ThemeProvider>
  );
}
