import Cookies from "js-cookie";
import { api } from "../../boot/axios";

export default function exampleService() {
  const headers = {
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  };

  const getAvisos = async (uuid) => {
    const { data } = await api.get(
      `portal/comunicados?fk_uuid_destinatario=${uuid}`,
      {
        headers,
      }
    );
    return data;
  };

  const postAviso = async (form) => {
    const { data } = await api.post("portal/comunicados", form, {
      headers,
    });
    return data;
  };

  const deleteAviso = async (uuid) => {
    const { data } = await api.delete(`portal/comunicados/${uuid}`, {
      headers,
    });
    return data;
  };

  const getFuncionarios = async () => {
    const { data } = await api.get(`portal/funcionario/lookup`, {
      headers,
    });
    return data;
  };

  return {
    getAvisos,
    postAviso,
    deleteAviso,
    getFuncionarios,
  };
}
