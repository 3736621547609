import * as React from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Archive from "./userView";
import UploadArchive from "./funcView";
const defaultTheme = createTheme();

export default function Dashboard() {
  const userData = useSelector((state) => state.userData);

  return (
    <ThemeProvider theme={defaultTheme}>
      {userData.funcionario ? <UploadArchive /> : <Archive />}
    </ThemeProvider>
  );
}
