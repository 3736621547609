import { api } from "../../boot/axios";
import UseApi from "../../composables/UseApi";

export default function exampleService() {
  const { csrfCookie } = UseApi();

  const resetPassword = async (form) => {
    await csrfCookie();

    const { data } = await api.post("/redefinir-senha", form);
    return data;
  };

  const forgotPassword = async (form) => {
    await csrfCookie();
    const { data } = await api.post("/esqueci-minha-senha", form);
    return data;
  };

  return {
    resetPassword,
    forgotPassword,
  };
}
